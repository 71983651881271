import { Button, Select } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { baseUrl } from "../../Config/baseUrl";

export default function AddProjectDetails() {
    const { id } = useParams()
    const { state } = useLocation();
    const { type } = useParams();
    const [data, setData] = useState();
    const [category, setCategory] = useState();
    const navegate = useNavigate();
    const getProject = () => {
        axios
            .get(`${baseUrl}/blog/project-image-api/project_id=${state}`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                console.log("RESPONSE ==== : ", res.data);
                setData(res.data?.data);
                // handleEdit(res.data?.news_details);
            })
            .catch((err) => {
                console.log("ERROR: ====", err);
                toast.error(err.message);
            });
    };

    useEffect(() => {
        state && getProject();
    }, [state]);

    console.log(data);

    const formik = useFormik({
        initialValues: {
            project: id,
            title: data?.title || "",
            image: data?.image || "",
            content: data?.content || "",
            projectcategory: data?.projectcategory || "",
            meta_title: data?.meta_title || "",
            author: data?.author || "",
            title1: data?.title1 || "",
            meta_description: data?.meta_description || "",

        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const reqbody = new FormData();
            reqbody.append("store", localStorage.getItem("store_id"));
            reqbody.append("content", values.content);
            reqbody.append("title", values.title);
            reqbody.append("image", values.image);
            reqbody.append("projectcategory", values.projectcategory);
            reqbody.append("meta_title", values.meta_title);
            reqbody.append("author", values.author);
            reqbody.append("title1", values.title1);
            reqbody.append("meta_description", values.meta_description);

            reqbody.append("project", id)
            type === "update" && reqbody.append("project_id", state);
            type === "add"
                ? axios
                    .post(
                        `${baseUrl}/blog/project-image-api/`,
                        { ...values, store: localStorage.getItem("store_id") },
                        {
                            headers: {
                                Authorization: `Token ${localStorage.getItem("token")}`,
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((response) => {
                        toast.success(response.data.message);
                        navegate(`/projectdetails/${id}`);
                    })
                    .catch((error) => {
                        toast.error("Error adding blog:", error);
                    })
                : axios
                    .patch(`${baseUrl}/blog/project-image-api/`, reqbody, {
                        data: {
                            project_image_id: id
                        },
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Token ${localStorage.getItem("token")}`,
                        },
                    })
                    .then((response) => {
                        toast.success(response.data.message);
                        navegate(`/projectdetails/${id}`);
                    })
                    .catch((error) => {
                        console.error("Error adding blog:", error);
                    });
        },
    });

    const blogData = () => {
        axios
            .get(`${baseUrl}/blog/blog-category-api/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                setCategory(res?.data?.data);
            })
            .catch((err) => {
                toast.error(err.response?.data.message);
            });
    };
    useEffect(() => {
        blogData();
    }, []);
    return (
        <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
            <div className="flex flex-col justify-center w-full lg:w-3/5">
                <div className="mt-5 bg-white shadow-lg">
                    <form onSubmit={formik.handleSubmit}>

                        <div className="p-2 border-gray-300 rounded-md ">
                            <div className="grid grid-cols-2 gap-5">

                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">title</p>
                                        <input
                                            id="title"
                                            placeholder="Enter title"
                                            value={formik.values.title}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Metatitle</p>
                                        <input
                                            id="meta_title"
                                            placeholder="Enter title"
                                            value={formik.values.meta_title}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Metatitle</p>
                                        <input
                                            id="title1"
                                            placeholder="Enter title"
                                            value={formik.values.title1}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Author</p>
                                        <input
                                            id="author"
                                            placeholder="Enter author"
                                            value={formik.values.author}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}

                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Author</p>
                                        <input
                                            id="author"
                                            placeholder="Enter author"
                                            value={formik.values.author}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}

                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Content</p>
                                        <input
                                            id="content"
                                            placeholder="Enter author"
                                            value={formik.values.content}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}

                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Category</p>
                                        <select
                                            id="projectcategory"
                                            placeholder="Enter author"
                                            value={formik.values.projectcategory}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}>
                                            <option>Select categrory</option>
                                            {category?.map((cat) => {
                                                return (
                                                    <option key={cat.id} value={cat.id}>
                                                        {cat.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Image</p>
                                        <input
                                            id="images"
                                            type="file"
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={(event) =>
                                                formik.setFieldValue("image", event.target.files[0])
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="w-full pl-3 ">
                                    <Button type="submit" variant="contained">
                                        Add
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
}
