import useImageCrop from "./index";

export const ImagePicker = ({ placeholder, handleChange }) => {
  const { ImageUploader } = useImageCrop({ onComplete: handleChange });

  return (
    <div className="!capitalize border border-gray-300 rounded p-2 px-4 m-1">
      <ImageUploader>{placeholder}</ImageUploader>
    </div>
  );
};
