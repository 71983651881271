import { Add, Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Rating,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { headers } from "..";
import { MkxInput } from "react-mkx-components";

const ManageTestimonials = ({ selected, setSelected, refetch }) => {
  const [open, setOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const clientsFn = async () => {
    try {
      const response = await axios.get(
        "https://m1blog.aaragroups.com/client-api/",
        { headers }
      );
      setClients(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    clientsFn();
  }, []);

  const addTestimonialsFn = async (reqbody) => {
    try {
      const response = await axios.post(
        "https://m1blog.aaragroups.com/client-feedback-api/",
        reqbody,
        { headers }
      );
      toast.success(response.data.message);
      refetch();
      formik.resetForm();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTestimonialsFn = async (reqbody) => {
    try {
      const response = await axios.put(
        "https://m1blog.aaragroups.com/client-feedback-api/",
        reqbody,
        { headers }
      );
      toast.success(response.data.message);
      refetch();
      setSelected(null);
      formik.resetForm();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      client_id: clients?.find((i) => i.name === selected?.client)?.id ?? "",
      comment: selected?.comment ?? "",
      url: selected?.url ?? "",
      feedback: selected?.feedback ?? 0,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      selected
        ? updateTestimonialsFn({ ...values, client_feedback_id: selected.id })
        : addTestimonialsFn(values);
    },
  });

  return (
    <>
      <Button
        startIcon={<Add />}
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        Testimonial
      </Button>
      <Dialog
        open={Boolean(open || selected)}
        onClose={() => {
          setOpen(false);
          setSelected(null);
        }}
        PaperProps={{ className: "w-[600px]" }}
      >
        <DialogTitle className="flex items-center !p-2 justify-between">
          <p>Testimonial</p>
          <IconButton
            size="small"
            onClick={() => {
              setOpen(false);
              setSelected(null);
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <div className="flex justify-center py-2">
              <Rating
                name="feedback"
                value={formik.values.feedback}
                onChange={(_, newValue) =>
                  formik.setFieldValue("feedback", newValue)
                }
              />
            </div>

            <TextField
              select
              margin="dense"
              label="Client"
              type="text"
              size="small"
              fullWidth
              name="client_id"
              value={formik.values.client_id}
              onChange={formik.handleChange}
            >
              {clients?.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>

            <MkxInput
              margin="dense"
              size="small"
              name="url"
              fullWidth
              label="Video URL"
              formik={formik}
            />

            <TextField
              margin="dense"
              label="Comment"
              type="text"
              fullWidth
              multiline
              rows={4}
              size="small"
              name="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
            />
            <DialogActions className="!px-0">
              <Button
                disableElevation
                onClick={() => setOpen(false)}
                variant="contained"
                color="inherit"
              >
                Cancel
              </Button>
              <Button
                disableElevation
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default ManageTestimonials;
