import { Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PosPage = () => {
  const [content, setContent] = useState("");
  const navegate = useNavigate();
  const handleEditorChange = (value) => {
    setContent(value);
  };
  const addnews = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("store", localStorage.getItem("store_id"));
    /* reqbody.append("Title", title);
    reqbody.append("meta_keyword", metaKeyword);
    reqbody.append("meta_description", mataDescription);
   
    reqbody.append("images", image); */
    reqbody.append("content", content);
    axios
      .post(
        "https://m1blog.aaragroups.com/blog/change-page-content-api/",
        reqbody,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        navegate("/news");
      })
      .catch((error) => {
        console.error("Error adding blog:", error);
      });
  };

  return (
    <div className="flex flex-col w-full">
      <div className="px-5 mt-5 bg-white shadow-lg">
        <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
          Add News
        </p>
        <form onSubmit={addnews}>
          <div className="w-full pl-3 ">
            <div>
              <p className="p-1 text-sm font-semibold">content:</p>
              <ReactQuill
                className="w-full text-lg font-semibold bg-gray-100 border "
                value={content}
                onChange={handleEditorChange}
                rows={2}
              ></ReactQuill>
            </div>
          </div>
          <div className="flex items-center justify-center w-full pl-3 my-5 mt-5 ">
            <Button className="!w-32" type="submit" variant="contained">
              Add News
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PosPage;
