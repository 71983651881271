import { Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ImagePicker } from "../../../Shared/useImageCrop/ImagePicker";

export default function Addnews() {
  const [title, setTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [mataDescription, setMetaDescription] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const navegate = useNavigate();
  const handleEditorChange = (value) => {
    setContent(value);
  };

  const addnews = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("store", localStorage.getItem("store_id"));
    reqbody.append("Title", title);
    reqbody.append("meta_keyword", metaKeyword);
    reqbody.append("meta_description", mataDescription);
    reqbody.append("Content", content);
    reqbody.append("images", image);

    axios
      .post(
        "https://m1blog.aaragroups.com/blog/api/admin/api/news_list_api/",
        reqbody,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        navegate("/news");
      })
      .catch((error) => {
        console.error("Error adding blog:", error);
      });
  };

  return (
    <div className="flex flex-col w-full">
      <div className="px-5 mt-5 bg-white shadow-lg">
        <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
          Add News
        </p>
        <form onSubmit={addnews}>
          <div className="grid grid-cols-2 gap-5 ">
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Title</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Meta Description</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setMetaDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Meta Keyword:</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setMetaKeyword(e.target.value)}
                />
              </div>
            </div>

            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">File:</p>
                <ImagePicker
                  placeholder="Input Image"
                  handleChange={setImage}
                />
              </div>
            </div>
            <div className="w-full pl-3 "></div>
          </div>
          <div className="w-full pl-3 ">
            <div>
              <p className="p-1 text-sm font-semibold">Content:</p>
              <ReactQuill
                className="w-full text-lg font-semibold bg-gray-100 border "
                value={content}
                onChange={handleEditorChange}
              ></ReactQuill>
            </div>
          </div>
          <div className="flex items-center justify-center w-full pl-3 my-5 mt-5 ">
            <Button className="!w-32" type="submit" variant="contained">
              Add News
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
