import { Button } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../Config/baseUrl";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export default function AddCMS() {

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {

      matchVisual: false,
    }
  };

  const { state } = useLocation();
  const { type } = useParams();
  const [data, setData] = useState([]);
  const store = localStorage.getItem("store_id");
  const navegate = useNavigate();
  const getCMS = () => {
    axios
      .get(
        `${baseUrl}/blog/change-page-content-api/?store_id=${store}&id=${state}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log("RESPONSE ==== : ", res.data);
        setData(res.data?.data);

      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    state && getCMS();
  }, [state]);



  const formik = useFormik({
    initialValues: {
      heading: data?.heading || "",
      content: data?.content || "",
      sub_heading: data?.sub_heading || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqbody = new FormData();
      reqbody.append("store_id", localStorage.getItem("store_id"));
      reqbody.append("heading", values.heading);
      reqbody.append("sub_heading", values.sub_heading);
      reqbody.append("content", values.content);
      reqbody.append("page_id", 1);
      type === "update" && reqbody.append("id", state);
      type === "add"
        ? axios
          .post(
            `${baseUrl}/blog/change-page-content-api/`,
            { ...values, store: localStorage.getItem("store_id") },
            {
              headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            toast.success(response.data.message);
            navegate("/cms");
          })
          .catch((error) => {
            toast.error("Error adding blog:", error);
          })
        : axios
          .patch(`${baseUrl}/blog/change-page-content-api/`, reqbody, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            toast.success(response.data.message);
            navegate("/cms");
          })
          .catch((error) => {
            console.error("Error adding blog:", error);
          });
    },
  });

  return (
    <div className="flex flex-col justify-center gap-5 py-4 ">

      <div className="flex flex-col justify-center w-full lg:w-[70%]">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-2 border-gray-300 rounded-md ">
              <div className="flex flex-col gap-5">
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Heading</p>
                    <input
                      id="heading"
                      placeholder="Enter Heading"
                      value={formik.values.heading}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Sub Heading</p>
                    <input
                      id="sub_heading"
                      placeholder="Enter Sub Heading"
                      value={formik.values.sub_heading}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Content</p>
                    {/* <input
                      id="content"
                      rows="5"
                      placeholder="Enter Content"
                      value={formik.values.content}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    /> */}
                  </div>
                </div>


              </div>
            </div>

            <ReactQuill
              className="h-[25rem]"
              id="content"
              theme='snow'
              value={formik.values.content}
              onChange={(value) => formik.setFieldValue("content", value)}
              formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']}
              placeholder="Write something amazing..."
              modules={modules}
            />
            <div className="w-full pl-3 ">
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </div>
          </form>
        </div>

      </div>



    </div>
  );
}
