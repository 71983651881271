import { Avatar, Button } from "@mui/material";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../Assets/logo1.png";

const Header = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("email");
  const user_name = localStorage.getItem("user_name");

  const handleLogoutReseller = () => {
    axios
      .post(
        `https://m1blog.aaragroups.com/blog/blog-store-logout/`,
        { email: email },
        { headers: { Token: localStorage.getItem("token") } }
      )
      .then(() => {
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.data.message);
      });
  };

  return (
    <>
      <div className="flex items-center justify-between h-16 p-2 bg-white rounded-lg shadow">
        <p className="text-xl font-bold">Blog Admin</p>
        <div className="flex items-center justify-center gap-3">
          <Avatar alt="D" src={logo} className=" !bg-gray-400" />
          <p className="font-semibold capitalize">{user_name}</p>
          <Button
            onClick={handleLogoutReseller}
            disableElevation
            variant="contained"
            className="!bg-red-500 !capitalize text-white"
          >
            Log Out
          </Button>
        </div>
      </div>
    </>
  );
};

export default Header;
