import React, { useEffect, useState } from 'react'
import { baseUrl } from '../../Config/baseUrl';
import { toast } from 'react-toastify';
import axios from 'axios';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

const MetaDetails = () => {
    const { id } = useParams()
    const [data, setData] = useState();
    const navigate = useNavigate();

    const getMeta = () => {
        axios
            .get(`${baseUrl}/blog/meta-api/?page_title=${id}`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                setData(res?.data?.data);
                console.log(res?.data?.data);
            })

    };

    const deleteMeta = (ids) => {

        const formData = new FormData();
        formData.append("metamodel_id", ids);

        axios
            .delete(`${baseUrl}/blog/meta-api/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
                data: formData
            })
            .then((res) => {
                console.log("meta deleted successfully");
                getMeta();
                toast.success(res?.data?.message);
            })
            .catch((err) => {
                toast.error("Error deleting blog", err?.response?.data?.message);
            });
    };


    useEffect(() => {
        getMeta();
    }, []);
    return (
        <div>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Meta</p>
            <div className="flex justify-end item-center w-[100%]">
                <NavLink to={`/metadetails/${id}/add`}>
                    <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <div className="overflow-x-auto max-w-screen">
                <table className="min-w-full border border-collapse border-gray-300 table-auto">
                    <thead>
                        <tr className="bg-gray-100 ">
                            <th className="px-4 py-2 text-lg font-semibold text-left text-black">Meta Title</th>
                            <th className="px-4 py-2 text-lg font-semibold text-left text-black">Meta Description</th>
                            <th className="flex justify-center px-20 py-2 text-lg font-semibold text-left text-black">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item) => (
                            <tr key={item.id} className="border-b border-gray-300">
                                <td className="px-4 py-2 overflow-hidden text-lg text-black text-ellipsis">
                                    <p >{item.meta_title}</p>
                                </td>
                                <td className="px-4 py-2 overflow-hidden text-lg text-black text-ellipsis ">
                                    <p>{item?.meta_description}</p>
                                </td>
                                <td className="px-4 py-2 overflow-hidden text-lg text-black whitespace-nowrap text-ellipsis">
                                    <div className="flex justify-center space-x-4">
                                        <Button
                                            onClick={() => navigate(`/metadetails/${id}/update`, { state: item.id })}
                                            variant="outlined"
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            onClick={() => deleteMeta(item.id)}
                                            variant="outlined"
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>


                </table>
            </div>

        </div>
    )
}

export default MetaDetails