import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSliderByIdFn, patchSliderFn, postSliderFn } from "../../../Services/Slider";

const AddSlider = () => {
    const { state } = useLocation();
    const { status } = useParams();
    const navigate = useNavigate();

    const { mutate: postSlider } = useMutation(postSliderFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("New Slider Created.");
                navigate("/slider");
            }
        }
    });

    const { mutate: patchSlider } = useMutation(patchSliderFn, {
        onSuccess: (response) => {
            if (response.data?.response_code === 200) {
                toast.success("Slider updated successfully");
                navigate("/slider");
            }
        }
    });

    const { data: getSliderById } = useQuery({
        queryFn: () => getSliderByIdFn(state),
        queryKey: ["get-slider-by-id", state],
        enabled: !!state,
    });

    const slider = getSliderById?.data?.data;
    const formik = useFormik({
        initialValues: {
            title: slider?.title || "",
            url: slider?.url || "",
            image: null,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const reqbody = new FormData();
            reqbody.append("url", values.url); // Change `values.link` to `values.url`
            reqbody.append("title", values.title); // Change `values.description` to `values.title`
            if (values.image) {
                reqbody.append("image", values.image);
            }

            if (status === "update") {
                reqbody.append("slider_id", state);
                patchSlider(reqbody);
            } else {
                if (!values.image) {
                    return toast.error("Image is required.");
                }
                postSlider(reqbody);
            }
        },
    });

    const handleImageChange = (event) => {
        formik.setFieldValue("image", event.target.files[0]);
    };

    return (
        <div className="flex flex-col w-full">
            <div className="px-5 mt-5 bg-white shadow-lg">
                <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
                    {status === "update" ? "Update Slider" : "Add Slider"}
                </p>
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-2 gap-5">
                        <div className="w-full pl-3">
                            <div>
                                <p className="p-1 text-sm font-semibold">Link</p>
                                <input
                                    className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                    name="url" // Change `name` to `url`
                                    value={formik.values.url}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className="w-full pl-3">
                            <div>
                                <p className="p-1 text-sm font-semibold">File:</p>
                                <input
                                    type="file"
                                    onChange={handleImageChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full pl-3">
                        <div>
                            <p className="p-1 text-sm font-semibold">Description:</p>
                            <ReactQuill
                                className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                                value={formik.values.title} // Ensure title is used
                                onChange={(value) => formik.setFieldValue('title', value)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-center w-full pl-3 my-5 mt-5">
                        <Button className="!w-32" type="submit" variant="contained">
                            {status === "update" ? "Update" : "Add"}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSlider;
