import { Button } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../Config/baseUrl";
import ReactQuill from "react-quill";

export default function AddFAQ() {
  const { id } = useParams()
  const { state } = useLocation();
  const { type } = useParams();
  const [data, setData] = useState();
  const navegate = useNavigate();
  const getFAQ = () => {
    axios
      .get(`${baseUrl}/blog/faq-api/?faq_id=${state}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("RESPONSE ==== : ", res.data);
        setData(res.data?.data);
        // handleEdit(res.data?.news_details);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    state && getFAQ();
  }, [state]);

  console.log(data);

  const formik = useFormik({
    initialValues: {
      page_id: id,
      question: data?.question || "",
      answer: data?.answer || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqbody = new FormData();
      reqbody.append("store", localStorage.getItem("store_id"));
      reqbody.append("question", values.question);
      reqbody.append("answer", values.answer);
      reqbody.append("page_id", id)
      type === "update" && reqbody.append("faq_id", state);
      type === "add"
        ? axios
          .post(
            `${baseUrl}/blog/faq-api/`,
            { ...values, store: localStorage.getItem("store_id") },
            {
              headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            toast.success(response.data.message);
            navegate(`/faq/${id}`);
          })
          .catch((error) => {
            toast.error("Error adding blog:", error);
          })
        : axios
          .patch(`${baseUrl}/blog/faq-api/`, reqbody, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            toast.success(response.data.message);
            navegate(`/faq/${id}`);
          })
          .catch((error) => {
            console.error("Error adding blog:", error);
          });
    },
  });


  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {

      matchVisual: false,
    }
  };


  return (
    <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
      <div className="flex flex-col justify-center w-full lg:w-3/5">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-2 border-gray-300 rounded-md ">
              <div className="flex flex-col gap-5">
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Question</p>
                    <input
                      id="question"
                      placeholder="Enter Question"
                      value={formik.values.question}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Answer</p>
                    <ReactQuill
                      id="answer"
                      theme='snow'
                      placeholder="Enter answer"
                      value={formik.values.answer}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(value) => formik.setFieldValue("answer", value)}
                      modules={modules}
                      formats={['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video']}
                    />
                  </div>
                </div>
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Slug:</p>
                    <input
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Description:</p>
                    <input
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                      <div>
                        <p className="p-1 text-sm font-semibold">Author:</p>
                        <input
                          placeholder="Title *"
                          className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                          // onChange={(e) => setHousNo(e.target.value)}
                        />
                      </div>
                    </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Categrory</p>
                    <select
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option>Select categrory</option>
                      {data?.map((cat) => {
                        return (
                          <option key={cat.id} value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">File:</p>
                    <ImagePicker
                      placeholder="Input Image"
                      handleChange={setImage}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Content:</p>
                    <ReactQuill
                      className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                      value={content}
                      onChange={handleEditorChange}
                    ></ReactQuill>
                  </div>
                </div> */}
                <div className="w-full pl-3 ">
                  <Button type="submit" variant="contained">
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
