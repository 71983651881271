import {
  Airplay,
  Comment,
  ContactEmergency,
  Grading,
  Handshake,
  Link,
  People,
  QuestionAnswer,
} from "@mui/icons-material";
import BookIcon from "@mui/icons-material/Book";
import CategoryIcon from "@mui/icons-material/Category";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import WorkIcon from "@mui/icons-material/Work";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

export const navItems = [
  {
    id: 1,
    navLink: "/blog",
    navItem: "Blog",
    navIcon: <BookIcon className="text-pink-500" />,
  },
  {
    id: 2,
    navLink: "/category",
    navItem: "Category",
    navIcon: <CategoryIcon className="text-amber-700" />,
  },
  {
    id: 3,
    navLink: "/sub-category",
    navItem: "Sub Category",
    navIcon: <CategoryIcon className="text-cyan-500" />,
  },
  {
    id: 4,
    navLink: "/news",
    navItem: "News",
    navIcon: <NewspaperIcon className="text-green-500" />,
  },
  {
    id: 5,
    navLink: "/career",
    navItem: "Career",
    navIcon: <WorkIcon className="text-violet-500" />,
  },
  {
    id: 6,
    navLink: "/clients",
    navItem: "Clients",
    navIcon: <People className="text-orange-500" />,
  },
  {
    id: 7,
    navLink: "/partners",
    navItem: "Partners",
    navIcon: <Handshake className="text-rose-500" />,
  },
  {
    id: 8,
    navLink: "/testimonials",
    navItem: "Testimonials",
    navIcon: <Grading className="text-blue-500" />,
  },
  {
    id: 9,
    navLink: "/comments",
    navItem: "Comments",
    navIcon: <Comment className="text-rose-500" />,
  },
  {
    id: 10,
    navLink: "/book",
    navItem: "Book",
    navIcon: <MenuBookIcon className="text-red-500" />,
  },
  /* {
    id: 11,
    navLink: "/faq",
    navItem: "FAQ",
    navIcon: <QuestionAnswer className="text-red-500" />,
  }, */
  {
    id: 12,
    navLink: "/contact-us",
    navItem: "Contact Us",
    navIcon: <ContactEmergency className="text-red-500" />,
  },
  /*  {
     id: 13,
     navLink: "/pos",
     navItem: "Pos",
     navIcon: <Airplay className="text-red-500" />,
   }, */

  {
    id: 14,
    navLink: "/cms",
    navItem: "CMS",
    navIcon: <SettingsApplicationsIcon className="text-red-700" />,
  },
  {
    id: 15,
    navLink: "/faq-pages",
    navItem: "FAQ Pages",
    navIcon: <QuestionAnswer className="text-red-700" />,
  },
  {
    id: 15,
    navLink: "/gallery",
    navItem: "Gallery",
    navIcon: <QuestionAnswer className="text-red-700" />,
  },
  {
    id: 15,
    navLink: "/slider",
    navItem: "Slider",
    navIcon: <QuestionAnswer className="text-red-700" />,
  },
  {
    id: 16,
    navLink: "/project",
    navItem: "Project",
    navIcon: <WorkIcon className="text-red-700" />,

  },
  {
    id: 17,
    navLink: "/project_category",
    navItem: "Project Category",
    navIcon: <CategoryIcon className="text-amber-700" />,
  },
  {
    id: 18,
    navLink: "/meta",
    navItem: "Meta",
    navIcon: <Link className="text-red-700" />,
  },

];
