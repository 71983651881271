import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ImagePicker } from "../../../Shared/useImageCrop/ImagePicker";
import { baseUrl } from "../../../Config/baseUrl";

const AddBlog = () => {
  const [metaTitle, setMetaTitle] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState("");
  const [subData, setSubData] = useState([]);
  const [subCategory, setSubCategory] = useState();
  const [subTitle, setSubTitle] = useState("");
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);
  const navegate = useNavigate();
  const handleEditorChange = (value) => {
    setContent(value);
  };

  const addBlog = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("store", localStorage.getItem("store_id"));
    reqbody.append("title", title);
    reqbody.append("sub_title", subTitle);
    reqbody.append("meta_title", metaTitle);
    reqbody.append("meta_description", description);
    reqbody.append("Content", content);
    reqbody.append("images", image);
    reqbody.append("category", category);
    subCategory && reqbody.append("sub_category", subCategory);
    reqbody.append("auth", author);

    axios
      .post(`${baseUrl}/blog/blog-api/`, reqbody, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.response_code === 200) {
          toast.success(response.data.message);
          navegate(-1);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error adding blog:", error);
      });
  };
  const blogData = () => {
    axios
      .get(`${baseUrl}/blog/blog-category-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };
  const blogSubCategory = (id) => {
    axios
      .get(`${baseUrl}/blog/blog-subcategory-api/`, {
        params: { category_id: id },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setSubData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };
  useEffect(() => {
    blogSubCategory(category);
  }, [category]);
  useEffect(() => {
    blogData();
  }, []);
  return (
    <div className="flex flex-col w-full ">
      <div className="px-5 mt-5 bg-white shadow-lg">
        <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
          Add Blog
        </p>
        <form onSubmit={addBlog}>
          <div className="grid grid-cols-2 gap-5">
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Title</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">MetaTitle</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">SubTitle</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setSubTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Author</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Slug:</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
            </div> */}
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Description:</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Categrory</p>
                <select
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option>Select categrory</option>
                  {data?.map((cat) => {
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Sub Categrory</p>
                <select
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <option>Select sub categrory</option>
                  {subData?.length > 0 ? (
                    subData?.map((cat) => {
                      return (
                        <option key={cat.id} value={cat.id}>
                          {cat.name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </select>
              </div>
            </div>
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">File:</p>
                {/* <input type="file" onChange={handleImageChange}></input> */}
                <ImagePicker
                  placeholder="Input Image"
                  handleChange={setImage}
                />
              </div>
            </div>
          </div>

          <div className="w-full pl-3 ">
            <div>
              <p className="p-1 text-sm font-semibold">Content:</p>
              <ReactQuill
                className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                value={content}
                onChange={handleEditorChange}
              ></ReactQuill>
            </div>
          </div>
          <div className="flex items-center justify-center w-full pl-3 my-5 mt-5 ">
            <Button className="!w-32" type="submit" variant="contained">
              Add
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddBlog;
