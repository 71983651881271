import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../Config/baseUrl";

export default function FAQ() {
  const { id } = useParams()
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const getCreer = () => {
    axios
      .get(`${baseUrl}/blog/faq-api/?page_id=${id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setData(res?.data?.data);
        console.log(res?.data?.data);
      })

  };

  const deleteFAQ = (ids) => {
    const formData = new FormData();
    formData.append('faq_id', ids);
    axios
      .delete(
        `${baseUrl}/blog/faq-api/`,

        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: formData,
        }
      )
      .then((res) => {
        console.log("Blog deleted successfully");
        getCreer();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getCreer();
  }, []);
  console.log("first", data)
  return (
    <>
      <p className="my-3 text-5xl text-center text-blue-900 underline">FAQ</p>
      <div className="flex justify-end item-center w-[100%]">
        <NavLink to={`/faq/${id}/add`}>
          <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
            <Button variant="outlined">Add</Button>
          </div>
        </NavLink>
      </div>
      <div className="overflow-x-auto max-w-screen">
        <table className="min-w-full border border-collapse border-gray-300 table-auto">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-2 text-lg font-semibold text-left text-black">Question</th>
              <th className="px-4 py-2 text-lg font-semibold text-left text-black">Answer</th>
              <th className="px-4 py-2 text-lg font-semibold text-left text-black">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="px-4 py-2 overflow-hidden text-lg text-black text-ellipsis">
                  {item.question}
                </td>
                <td className="px-4 py-2 overflow-hidden text-lg text-black text-ellipsis"
                  dangerouslySetInnerHTML={{ __html: item.answer.replace(/<[^>]*>&nbsp;/g, '') }}>
                </td>
                <td className="px-4 py-2 text-lg text-black">
                  <div className="flex justify-start space-x-4">
                    <Button
                      onClick={() => navigate(`/faq/${id}/update`, { state: item.id })}
                      variant="outlined"
                    >
                      Update
                    </Button>
                    <Button onClick={() => deleteFAQ(item.id)} variant="outlined">
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </>
  );
}
