import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../Config/baseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import { Box, Modal, TextField, Button } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

const Comments = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCommentId, setOpenCommentId] = useState(null);
  const [editReplyId, setEditReplyId] = useState(null);
  const [reply, setReply] = useState("");
  const fetchBlogs = useCallback(async () => {
    try {
      const res = await axios.get(`${baseUrl}/blog/blog-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      });
      setBlogs(res.data.data);
    } catch (err) {
      console.error("ERROR: ====", err);
      toast.error(err?.message);
    }
  }, []);

  const fetchComments = useCallback(async () => {
    try {
      const res = await axios.get(`${baseUrl}/blog/comment-list-blog-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      });
      setComments(res.data.data);
    } catch (err) {
      console.error("ERROR: ====", err);
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteComment = async (comment_id) => {
    try {
      const res = await axios.delete(`${baseUrl}/blog/comment-list-blog-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          comment_id,
        },
      });
      toast.success("Comment Deleted");
      fetchComments();
    } catch (err) {
      console.error("ERROR: ====", err);
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const replyToComment = async (commentId) => {
    try {
      if (!reply) return toast.error("Reply box has no message.");
      const reqBody = {
        comment: commentId,
        reply,
        store_id: localStorage.getItem("store_id"),
      };
      await axios.post(`${baseUrl}/blog/reply-on-comment-api/`, reqBody, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      });
      toast.success("Reply submitted successfully!");
      setReply("");

      setOpenCommentId(null);
      fetchComments();
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchBlogs();
      fetchComments();
    } else {
      navigate("/");
    }
  }, [fetchBlogs, fetchComments, navigate]);

  const openReplyModal = (commentId) => {
    setOpenCommentId(commentId);
    setReply("");
  };

  const handleCloseModal = () => {
    setOpenCommentId(null);
    setEditReplyId(null);
    setReply("");
  };

  const deletereply = async (ids) => {
    const response = await axios
      .delete(`${baseUrl}/blog/reply-on-comment-api/`, {
        data: { reply_id: ids },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        fetchComments();
        console.log("User deleted successfully:", response.data);

      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const editReplyFn = async () => {
    try {
      if (!reply) return toast.error("Reply box has no message.");
      const reqBody = {
        reply_id: editReplyId,
        reply: reply,
      };
      await axios.patch(`${baseUrl}/blog/reply-on-comment-api/`, reqBody, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      });
      toast.success("Reply updated successfully!");
      handleCloseModal();
      fetchComments();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <div className="container p-3 mx-auto">
      <h1 className="mb-3 text-2xl text-center text-blue-900 underline">
        Comments
      </h1>
      {loading ? (
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
          {[...Array(5)].map((_, index) => (
            <Shimmer key={index} />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
          {blogs.map((blog) => {
            const filteredComments = comments.filter(
              (c) => c?.post === blog?.id
            );
            if (filteredComments.length === 0) return null;

            return (
              <div
                className="p-2 bg-white border rounded-lg shadow-sm"
                key={blog.id}
              >
                <h2 className="text-lg font-semibold">{blog?.meta_title}</h2>
                <p className="text-xs text-gray-500">{`Date: ${blog?.date_created?.slice(
                  0,
                  10
                )}`}</p>
                <div className="mt-1">
                  {filteredComments.map((comment) => (
                    <div
                      className="p-1 mt-1 border rounded-md bg-gray-50"
                      key={comment.id}
                    >
                      <p className="text-sm font-bold">{comment?.name}</p>
                      <p className="text-xs text-gray-600">{comment?.email}</p>
                      <p className="text-xs text-gray-600">
                        {comment?.created_at?.slice(0, 10)}
                      </p>
                      <p className="mt-1 text-xs">{comment?.comment}</p>
                      <div className="flex justify-end gap-4">
                        <button
                          className="px-2 py-1 mt-1 text-xs text-white bg-blue-500 rounded-sm hover:underline"
                          onClick={() => openReplyModal(comment.id)}
                        >
                          Reply
                        </button>
                        <button
                          className="px-2 py-1 mt-1 text-xs text-white bg-red-500 rounded-sm hover:underline"
                          onClick={() => deleteComment(comment.id)}
                        >
                          Delete
                        </button>
                      </div>

                      {comment.comment_replies.length > 0 && (
                        <div className="mt-2 ml-4">
                          <h4 className="text-sm font-semibold">Replies:</h4>
                          {comment.comment_replies.map((reply) => (
                            <div
                              key={reply.id}
                              className="flex justify-between p-1 mt-1 bg-gray-100 border rounded-md"
                            >
                              <div className="flex gap-2">
                                <p className="text-xs font-bold">Reply:</p>
                                <p className="text-xs">{reply.reply}</p>
                              </div>
                              <div className="gap-2">
                                <Edit
                                  fontSize="small"
                                  className="text-blue-400 cursor-pointer"
                                  onClick={() => setEditReplyId(reply.id)}
                                />
                                <Delete
                                  fontSize="small"
                                  className="text-red-600 cursor-pointer"
                                  onClick={() => deletereply(reply.id)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Modal
        open={!!openCommentId}
        onClose={handleCloseModal}
        aria-labelledby="reply-modal-title"
        aria-describedby="reply-modal-description"
      >
        <Box sx={modalStyle}>
          {openCommentId && (
            <>
              <h2 className="mb-2 text-lg font-semibold">Reply to Comment</h2>
              {comments
                .filter((comment) => comment.id === openCommentId)
                .map((comment) => (
                  <div key={comment.id} className="pb-2 mb-2 border-b">
                    <p className="text-sm font-bold">{comment?.name}</p>
                    <p className="text-xs text-gray-600">{comment?.comment}</p>
                  </div>
                ))}
              <TextField
                label="Your Reply"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={reply}
                onChange={(e) => setReply(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => replyToComment(openCommentId)}
              >
                Submit Reply
              </Button>
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={editReplyId}
        onClose={handleCloseModal}
        aria-labelledby="edit-reply-modal-title"
        aria-describedby="edit-reply-modal-description"
      >
        <Box sx={modalStyle}>
          {editReplyId && (
            <>
              <h2 className="mb-2 text-lg font-semibold">Edit Reply</h2>
              <TextField
                label="Your Reply"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={reply}
                onChange={(e) => setReply(e.target.value)}
                sx={{ mb: 2 }}
              />
              <div className="flex justify-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={editReplyFn}
                  sx={{ mr: 1 }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

const Shimmer = () => {
  return (
    <div className="animate-pulse">
      <div className="w-3/4 h-6 mb-2 bg-gray-200 rounded"></div>
      <div className="w-1/2 h-4 mb-2 bg-gray-200 rounded"></div>
      <div className="w-full h-4 mb-2 bg-gray-200 rounded"></div>
      <div className="w-full h-4 mb-2 bg-gray-200 rounded"></div>
    </div>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default Comments;
