import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ImagePicker } from "../../../Shared/useImageCrop/ImagePicker";

export default function Newsupdate() {
  const [title, setTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [mataDescription, setMetaDescription] = useState("");
  const [content, setContent] = useState("");
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);
  const { id } = useParams();
  const navegate = useNavigate();
  const handleEditorChange = (value) => {
    setContent(value);
  };

  const addnews = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("Title", title);
    reqbody.append("meta_keyword", metaKeyword);
    reqbody.append("meta_description", mataDescription);
    reqbody.append("Content", content);
    image && reqbody.append("images", image);

    axios
      .put(
        `https://m1blog.aaragroups.com/blog/api/admin/api/news_deatils_api/?id=${id}`,
        reqbody,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        navegate("/news");
      })
      .catch((error) => {
        toast.error("Some error occured");
      });
  };

  const handleEdit = (prevData) => {
    setTitle(prevData?.[0]?.Title);
    setMetaDescription(prevData?.[0]?.meta_description);
    setMetaKeyword(prevData?.[0]?.meta_keyword);
  };

  useEffect(() => {
    const getNews = () => {
      axios
        .get("https://m1blog.aaragroups.com/blog/api/news_list_api/", {
          headers: {
            token: localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          setData(res.data?.data);
          handleEdit(res.data?.data);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    };

    getNews();
  }, []);

  const dataNews = data?.filter((i) => i.id === Number(id));

  useEffect(() => {
    if (dataNews) {
      setContent(dataNews?.[0]?.Content);
    }
  }, [dataNews]);

  return (
    <div className="flex flex-col w-full">
      <div className="mt-5 bg-white shadow-lg px-55">
        <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
          Update News
        </p>
        <form onSubmit={addnews}>
          <div className="grid grid-cols-2 gap-5">
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Title</p>
                <input
                  value={title}
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Meta Description</p>
                <input
                  value={mataDescription}
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setMetaDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Meta Keyword:</p>
                <input
                  value={metaKeyword}
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setMetaKeyword(e.target.value)}
                />
              </div>
            </div>

            <div className="w-full pl-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">File:</p>
                <ImagePicker
                  placeholder="Input Image"
                  handleChange={setImage}
                />
              </div>
            </div>
          </div>
          <div className="w-full pl-3 ">
            <div>
              <p className="p-1 text-sm font-semibold">Content:</p>
              <ReactQuill
                className="w-full text-lg font-semibold bg-gray-100 border "
                value={content}
                onChange={handleEditorChange}
              ></ReactQuill>
            </div>
          </div>
          <div className="flex items-center justify-center w-full pl-3 my-5 mt-5 ">
            <Button type="submit" variant="contained">
              Update News
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
