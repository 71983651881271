import { Button } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../Config/baseUrl";

export default function AddSubCategory() {
  const { state } = useLocation();
  const { type } = useParams();
  const [data, setData] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const navegate = useNavigate();

  const getSubCategory = () => {
    axios
      .get(`${baseUrl}/blog/blog-subcategory-api/?subcategory_id=${state}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("RESPONSE ==== : ", res.data);
        setData(res.data?.data);
        // handleEdit(res.data?.news_details);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        toast.error(err.message);
      });
  };
  const blogData = () => {
    axios
      .get(`${baseUrl}/blog/blog-category-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setCategoryData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };
  useEffect(() => {
    state && getSubCategory();
  }, [state]);
  useEffect(() => {
    blogData();
  }, []);

  console.log(data);

  const formik = useFormik({
    initialValues: {
      name: data?.name || "",
      images: null,
      category: data?.category || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqbody = new FormData();
      reqbody.append("store", localStorage.getItem("store_id"));
      reqbody.append("name", values.name);
      reqbody.append("category", values.category);
      // reqbody.append("images", values.images);
      type !== "update"
        ? reqbody.append("images", values.images)
        : formik.values.images && reqbody.append("images", values.images);
      type === "update" && reqbody.append("subcategory_id", state);
      type === "add"
        ? axios
            .post(
              `${baseUrl}/blog/blog-subcategory-api/`,
              { ...values, store: localStorage.getItem("store_id") },
              {
                headers: {
                  Authorization: `Token ${localStorage.getItem("token")}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.response_code === 200) {
                toast.success(response.data.message);
                navegate(-1);
              } else {
                toast.error(response.data.message);
              }
            })
            .catch((error) => {
              toast.error("Error adding blog:", error);
            })
        : axios
            .patch(`${baseUrl}/blog/blog-subcategory-api/`, reqbody, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${localStorage.getItem("token")}`,
              },
            })
            .then((response) => {
              if (response.data.response_code === 200) {
                toast.success(response.data.message);
                navegate(-1);
              } else {
                toast.error(response.data.message);
              }
            })
            .catch((error) => {
              console.error("Error adding blog:", error);
            });
    },
  });

  return (
    <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
      <div className="flex flex-col justify-center w-full lg:w-3/5">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-2 border-gray-300 rounded-md ">
              <div className="flex flex-col gap-5">
                <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Category</p>
                    <select
                      id="category"
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onClick={() => {
                        blogData();
                      }}
                      value={formik.values.category}
                      onChange={formik.handleChange}
                    >
                      <option>Select category</option>
                      {categoryData?.map((cat) => {
                        return (
                          <option key={cat.id} value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">
                      Sub Category Name
                    </p>
                    <input
                      id="name"
                      placeholder="Enter name"
                      value={formik.values.name}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Image</p>
                    <input
                      id="images"
                      type="file"
                      placeholder="Enter image"
                      // value={formik.values.images}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(event) =>
                        formik.setFieldValue("images", event.target.files[0])
                      }
                    />
                  </div>
                </div>
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Slug:</p>
                    <input
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Description:</p>
                    <input
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                      <div>
                        <p className="p-1 text-sm font-semibold">Author:</p>
                        <input
                          placeholder="Title *"
                          className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                          // onChange={(e) => setHousNo(e.target.value)}
                        />
                      </div>
                    </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Categrory</p>
                    <select
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option>Select categrory</option>
                      {data?.map((cat) => {
                        return (
                          <option key={cat.id} value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">File:</p>
                    <ImagePicker
                      placeholder="Input Image"
                      handleChange={setImage}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Content:</p>
                    <ReactQuill
                      className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                      value={content}
                      onChange={handleEditorChange}
                    ></ReactQuill>
                  </div>
                </div> */}
                <div className="w-full pl-3 ">
                  <Button type="submit" variant="contained">
                    {state ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
