import Blog from "../Components/Blog";
import AddBlog from "../Components/Blog/AddBlog";
import UpdateBlog from "../Components/Blog/UpdateBlog";
import Career from "../Components/Career";
import FAQ from "../Components/FAQ";
import Addcareer from "../Components/Career/AddCareer";
import UpdateCareer from "../Components/Career/UpdateCareer";
import Client from "../Components/Client";
import News from "../Components/News";
import Addnews from "../Components/News/AddNews";
import Newsupdate from "../Components/News/UpdateNews";
import Partners from "../Components/Partners";
import Testimonials from "../Components/Testimonials";
import AddFAQ from "../Components/FAQ/AddFAQ";
import Book from "../Components/Book";
import AddBook from "../Components/Book/AddBook";
import Category from "../Components/Category";
import AddCategory from "../Components/Category/AddCategory";
import SubCategory from "../Components/SubCategory";
import AddSubCategory from "../Components/SubCategory/AddSubCategory";
import ContactUs from "../Components/ContactUs";
import Comments from "../Components/Comments";
import PosPage from "../Components/Pages/Pos";
import CMS from "../Components/CMS";
import AddCMS from "../Components/CMS/ManageCMS";
/* import AddFAQPages from "../Components/FAQ/page Details"; */
import FAQpages from "../Components/FAQPages";
import AddFAQPage from "../Components/FAQPages/AddFaqPages";
import Gallery from "../Components/Gallery";
import AddGallery from "../Components/Gallery/AddGallery";
import GalleryImage from "../Components/GalleryImage";
import AddGalleryImage from "../Components/GalleryImage/AddGalleryImage";
import Slider from "../Components/Slider";
import AddSlider from "../Components/Slider/AddSlider";
import Project from "../Components/Project";
import AddProject from "../Components/Project/AddProject";
import ProjectDetails from "../AddProjectDetails";
import AddProjectDetails from "../AddProjectDetails/AddProject";
import MetaPages from "../Components/Meta";
import AddMetaPages from "../Components/Meta/AddMetaPages";
import MetaDetails from "../Components/Metadetails";
import AddMetaDetails from "../Components/Metadetails/MetaAddDetails";
import ProjectCategory from "../Components/ProjectCategory";
import AddProjectCategory from "../Components/ProjectCategory/AddProjectcategory";




export const routes = [
  {
    id: 1,
    path: "/blog",
    component: <Blog />,
  },
  {
    id: 2,
    path: "/addblog",
    component: <AddBlog />,
  },
  {
    id: 3,
    path: "/updateblog",
    component: <UpdateBlog />,
  },
  {
    id: 4,
    path: "/news",
    component: <News />,
  },
  {
    id: 5,
    path: "/addnews",
    component: <Addnews />,
  },
  {
    id: 6,
    path: "/newsupdate/:id",
    component: <Newsupdate />,
  },
  {
    id: 7,
    path: "/career",
    component: <Career />,
  },
  {
    id: 8,
    path: "/addcareer",
    component: <Addcareer />,
  },
  {
    id: 9,
    path: "/updateCareer/:id",
    component: <UpdateCareer />,
  },
  {
    id: 10,
    path: "/testimonials",
    component: <Testimonials />,
  },
  {
    id: 11,
    path: "/clients",
    component: <Client />,
  },
  {
    id: 11_1,
    path: "/comments",
    component: <Comments />,
  },
  {
    id: 12,
    path: "/partners",
    component: <Partners />,
  },
  {
    id: 13,
    path: "/faq/:id",
    component: <FAQ />,
  },
  {
    id: 14,
    path: "/faq/:id/:type",
    component: <AddFAQ />,
  },
  {
    id: 15,
    path: "/book",
    component: <Book />,
  },
  {
    id: 16,
    path: "/book/:type",
    component: <AddBook />,
  },
  {
    id: 17,
    path: "/category",
    component: <Category />,
  },
  {
    id: 18,
    path: "/category/:type",
    component: <AddCategory />,
  },
  {
    id: 19,
    path: "/sub-category",
    component: <SubCategory />,
  },
  {
    id: 20,
    path: "/sub-category/:type",
    component: <AddSubCategory />,
  },
  {
    id: 21,
    path: "/contact-us",
    component: <ContactUs />,
  },
  {
    id: 22,
    path: "/pos",
    component: <PosPage />,
  },
  {
    id: 23,
    path: "/cms",
    component: <CMS />,
  },
  {
    id: 14,
    path: "/cms/:type",
    component: <AddCMS />,
  },
  /*   {
      id: 15,
      path: "/faq/addpages/:id",
      component: <AddFAQPages />
    }, */
  {
    id: 17,
    path: "/faq-pages",
    component: <FAQpages />,
  },
  {
    id: 18,
    path: "/faq-pages/:type",
    component: <AddFAQPage />,
  },
  {
    id: 19,
    path: "/gallery",
    component: <Gallery />,
  },
  {
    id: 20,
    path: "/gallery/:type",
    component: <AddGallery />,
  },
  {
    id: 22,
    path: '/gallery-image',
    element: <GalleryImage />,
  },
  {
    id: 23,
    path: '/gallery-image/:state',
    element: <AddGalleryImage />,
  },
  {
    id: 24,
    path: "/slider",
    component: <Slider />,
  },
  {
    id: 25,
    path: "/slider/:type",
    component: <AddSlider />,
  },
  {
    id: 26,
    path: "/project",
    component: <Project />,
  },
  {
    id: 27,
    path: "/project/:type",
    component: <AddProject />,
  },
  {
    id: 28,
    path: "/projectdetails/:id",
    component: <ProjectDetails />,
  },
  {
    id: 29,
    path: "/projectdetails/:id/:type",
    component: <AddProjectDetails />,
  },
  {
    id: 30,
    path: "/meta",
    component: <MetaPages />,
  },

  {
    id: 31,
    path: "/meta/:type",
    component: <AddMetaPages />,
  },
  {
    id: 28,
    path: "/metadetails/:id",
    component: <MetaDetails />,
  },
  {
    id: 29,
    path: "/metadetails/:id/:type",
    component: <AddMetaDetails />,
  },

  {
    id: 30,
    path: "/project_category",
    component: <ProjectCategory />,
  },
  {
    id: 31,
    path: "/project_category/:type",
    component: <AddProjectCategory />,
  },

];
