import { Button } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ImagePicker } from "../../../Shared/useImageCrop/ImagePicker";
export default function Addcareer() {
  const [title, setTitle] = useState("");

  const [experience, setExperience] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const navegate = useNavigate();
  const handleEditorChange = (value) => {
    setContent(value);
  };

  const addBlog = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("store", localStorage.getItem("store_id"));
    reqbody.append("Title", title);
    reqbody.append("Experience", experience);
    reqbody.append("Content", content);
    reqbody.append("images", image);

    axios
      .post(
        "https://m1blog.aaragroups.com/blog/api/admin/api/job-list-api/",
        reqbody,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        navegate("/career");
      })
      .catch((error) => {
        console.error("Error adding blog:", error);
      });
  };

  return (
    <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
      <div className="flex flex-col justify-center w-full lg:w-3/5">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={addBlog}>
            <div className="p-2 border-gray-300 rounded-md ">
              <div className="flex flex-col gap-5">
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Title</p>
                    <input
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">
                      Experience/Fresher
                    </p>
                    <input
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setExperience(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Slug:</p>
                    <input
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Description:</p>
                    <input
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 ">
                      <div>
                        <p className="p-1 text-sm font-semibold">Author:</p>
                        <input
                          placeholder="Title *"
                          className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                          // onChange={(e) => setHousNo(e.target.value)}
                        />
                      </div>
                    </div> */}
                {/* <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Categrory</p>
                    <select
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option>Select categrory</option>
                      {data?.map((cat) => {
                        return (
                          <option key={cat.id} value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">File:</p>
                    <ImagePicker
                      placeholder="Input Image"
                      handleChange={setImage}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Content:</p>
                    <ReactQuill
                      className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                      value={content}
                      onChange={handleEditorChange}
                    ></ReactQuill>
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <Button type="submit" variant="contained">
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
