import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import { routes } from "./Routes";
import SignIn from "./Shared/Login";

function App() {
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<SignIn />} />
          <Route element={<Layout />}>
            {routes.map((route) => {
              return (
                <Route
                  key={route.id}
                  path={route.path}
                  element={route.component}
                />
              );
            })}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
