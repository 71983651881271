import { useCallback, useState } from "react";
import ImageUploaderModal from "./ImageUploader";

import "./index.css";

const useImageCrop = ({ onComplete }) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();
  const [imgFile, setImgFile] = useState();
  const [imageName, setImageName] = useState("");

  const [croppedImg, setCroppedImg] = useState();

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onClose = useCallback(() => {
    setImage("");
    setOpen(false);
  }, []);

  const onCancel = () => {
    setImgFile("");
    onClose();
  };

  const handleImageChange = useCallback(async (e) => {
    try {
      const file = e.target.files[0];
      setImgFile(file);
      setImageName(file?.name?.replace(/\s+/g, "_"));
      const imageDataUrl = await readFile(file);
      if (imageDataUrl) {
        setImage(imageDataUrl);
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const ImageUploader = ({ children }) => {
    return (
      <>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          id="image_input"
        />
        <label htmlFor="image_input" className="!cursor-pointer">
          {imgFile ? imgFile.name : children}
        </label>
        <ImageUploaderModal
          open={open}
          image={image}
          imageName={imageName}
          onClose={onClose}
          onCancel={onCancel}
          onComplete={onComplete}
          setCroppedImg={setCroppedImg}
        />
      </>
    );
  };

  return { ImageUploader, croppedImg };
};

export default useImageCrop;
