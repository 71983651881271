import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../Config/baseUrl";
import { ImagePicker } from "../../../Shared/useImageCrop/ImagePicker";

export default function UpdateBlog() {
  const { state } = useLocation();
  const [title, setTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState();
  const [subData, setSubData] = useState([]);
  const [subCategory, setSubCategory] = useState();
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);
  const [subTitle, setSubTitle] = useState("");
  // const [getBlogData, setGetBlogData] = useState([]);
  const navegate = useNavigate();
  const handleEditorChange = (value) => {
    setContent(value);
  };

  // const handleEdit = () => {
  //   // if (getBlogData) {
  //   setContent(getBlogData?.Content);
  //   setTitle(getBlogData?.title);
  //   setMetaTitle(getBlogData?.meta_title);
  //   setSubTitle(getBlogData?.sub_title);
  //   setAuthor(getBlogData?.auth);
  //   setSubCategory(getBlogData?.sub_category);
  //   setCategory(getBlogData?.category);
  //   setDescription(getBlogData?.meta_description);
  //   // }
  // };

  const addBlog = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("store", localStorage.getItem("store_id"));
    reqbody.append("title", title);
    // reqbody.append("sub_title", subTitle);
    reqbody.append("meta_title", metaTitle);
    reqbody.append("blog_id", state);
    reqbody.append("meta_description", description);
    reqbody.append("Content", content);
    image && reqbody.append("images", image);
    reqbody.append("category", category);
    subCategory && reqbody.append("sub_category", subCategory);
    reqbody.append("auth", author);

    axios
      .patch(`${baseUrl}/blog/blog-api/`, reqbody, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.response_code === 200) {
          toast.success(response.data.message);
          navegate(-1);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data.message);
      });
  };
  const blogData = () => {
    axios
      .get(`${baseUrl}/blog/blog-category-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };
  const blogSubCategory = (id) => {
    axios
      .get(`${baseUrl}/blog/blog-subcategory-api/`, {
        params: { category_id: id },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setSubData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };
  // useEffect(() => {
  //   blogSubCategory(category);
  // }, [category]);

  const getData = () => {
    axios
      .get(`${baseUrl}/blog/blog-api/`, {
        params: { blog_id: state },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        // setGetBlogData(res?.data?.data);
        setContent(res?.data?.data?.Content);
        setTitle(res?.data?.data?.title);
        setMetaTitle(res?.data?.data?.meta_title);
        setSubTitle(res?.data?.data?.sub_title);
        setAuthor(res?.data?.data?.auth);
        setSubCategory(res?.data?.data?.sub_category);
        setCategory(res?.data?.data?.category);
        setDescription(res?.data?.data?.meta_description);
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };
  useEffect(() => {
    blogSubCategory(category);
  }, [category]);

  useEffect(() => {
    blogData();
    state && getData();
  }, []);
  // console.log(getBlogData);
  return (
    <div className="flex flex-col w-full ">
      <div className="px-5 mt-5 bg-white shadow-lg">
        <p className="my-5 text-xl font-bold text-center border-b-2 border-black">
          Update Blog
        </p>
        <form onSubmit={addBlog}>
          <div className="grid grid-cols-2 gap-5">
            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Title</p>

                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </div>
            </div>
            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">MetaTitle</p>

                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setMetaTitle(e.target.value)}
                  value={metaTitle}
                />
              </div>
            </div>
            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">SubTitle</p>

                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setSubTitle(e.target.value)}
                  value={subTitle}
                />
              </div>
            </div>
            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Author</p>

                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setAuthor(e.target.value)}
                  value={author}
                />
              </div>
            </div>
            {/* <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Slug:</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setSlug(e.target.value)}
                  value={slug}
                />
              </div>
            </div> */}
            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Description:</p>
                <input
                  placeholder=""
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </div>
            </div>
            {/* <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Category</p>
                <Select
                  id="sub_category"
                  placeholder="Select Sub Category"
                  className="w-full  px-4  !py-0 !h-10 !m-1 border border-gray-300 rounded"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                
                  <MenuItem value={""}>Select sub category</MenuItem>
                  {data?.map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Sub Category</p>
                <Select
                  id="sub_category"
                  placeholder="Select Sub Category"
                  className="w-full  px-4  !py-0 !h-10 !m-1 border border-gray-300 rounded"
                  value={subCategory}
                  disabled={!category}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  
                  <MenuItem value={""}>Select sub category</MenuItem>
                  {subData?.map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div> */}

            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Category</p>
                <select
                  placeholder="Select Category"
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  onClick={() => {
                    blogData();
                  }}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {/* <option>Select category</option> */}
                  {data?.map((cat) => {
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Sub Category</p>
                <select
                  className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                  placeholder="Select Sub Category"
                  value={subCategory}
                  disabled={!category}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <option value={""}>Select sub category</option>
                  {subData?.map((cat) => {
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">File:</p>
                <ImagePicker
                  placeholder="Input Image"
                  handleChange={setImage}
                />
              </div>
            </div>
          </div>
          <div className="w-full pl-3 mt-3 ">
            <div>
              <p className="p-1 text-sm font-semibold">Content:</p>
              <ReactQuill
                className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                value={content}
                onChange={handleEditorChange}
              ></ReactQuill>
            </div>
          </div>
          <div className="flex items-center justify-center w-full pl-3 my-5 mt-3 ">
            <Button className="!bg-green-500" type="submit" variant="contained">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
