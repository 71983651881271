import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../Config/baseUrl";

const Blog = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const deleteBlog = (id) => {
    axios
      .delete(`${baseUrl}/blog/blog-api/`, {
        data: { blog_id: id },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response.data.response_code === 200) {
          toast.success(response.data.message);
          blogData();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log("Error deleting blog", err);
      });
  };

  const blogData = useCallback(() => {
    localStorage.getItem("token") ? navigate("/blog") : navigate("/");
    axios
      .get(`${baseUrl}/blog/blog-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        toast.error(err?.message);
      });
  }, [navigate]);

  useEffect(() => {
    blogData();
  }, [blogData]);

  return (
    <>
      <p className="my-3 text-5xl text-center text-blue-900 underline">Blog</p>
      <div className="flex justify-between item-center w-[100%]">
        <NavLink to="/addblog">
          <div className="text-xl font-bold !text-gray-600 p-3">
            <Button variant="outlined">Add</Button>
          </div>
        </NavLink>
      </div>

      {data?.map((item, idx) => {
        return (
          <div
            key={idx}
            className=" grid grid-cols-3 border border-gray-300 drop-shadow-2xl mx-auto rounded w-[98%] my-3"
          >
            <div className="flex flex-col items-center justify-center">
              <p className="px-4 py-4 text-2xl text-black">{item.title}</p>
              <p className="px-4 py-2">{item.meta_description}</p>
              {/* <p dangerouslySetInnerHTML={{ __html: item.content }}></p> */}
            </div>
            <div className="flex flex-col items-center justify-center w-full">
              <img
                key={item.images}
                src={`${item.images}?${Date.now()}`}
                alt="img"
                className="w-40 h-40"
              />
            </div>
            <div className="flex items-center justify-center w-full p-5">
              <div className="px-5 mt-32 ">
                <Button
                  onClick={() => navigate("/updateblog", { state: item.id })}
                  variant="outlined"
                >
                  Update
                </Button>
              </div>

              <div className="px-5 mt-32 ">
                <Button onClick={() => deleteBlog(item.id)} variant="outlined">
                  Delete
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Blog;
