import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getGalleryByIdFn, patchGalleryFn, postGalleryFn } from "../../../Services/Gallery";


export default function AddGallery() {
  const { state: locationState } = useLocation();
  const { type } = useParams();
  const navigate = useNavigate();

  const { data: fetchedData } = useQuery({
    queryKey: ["get-category-by-id", locationState, type === "update"],
    queryFn: () => getGalleryByIdFn({ gallery_id: locationState }),
    enabled: type === "update",
  });
  console.log("first", fetchedData)
  const { mutate: postGallery } = useMutation(postGalleryFn, {
    onSuccess: (response) => {
      if (response.data?.response_code === 200) {
        toast.success("Added New Gallery");
        navigate("/gallery");
      }
    },
  });

  const { mutate: patchGallery } = useMutation(patchGalleryFn, {
    onSuccess: (response) => {
      if (response.data?.response_code === 200) {
        toast.success("Updated Gallery");
        navigate("/gallery");
      }
      if (response.data?.response_code === 201) {
        toast.success(response.data?.message);
      }
    },

  });

  const data = fetchedData?.data?.data;

  const formik = useFormik({
    initialValues: {
      title: data?.title || "",
      image: null,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("image", values.image);

      if (locationState && type === "update") {
        formData.append("gallery_id", locationState);
        if (!values.image) formData.delete("image");
        patchGallery(formData);
      } else {
        if (!values.image) return toast.error("Image is required");
        postGallery(formData);
      }
    },
  });

  return (
    <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
      <div className="flex flex-col justify-center w-full lg:w-3/5">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-2 border-gray-300 rounded-md">
              <div className="flex flex-col gap-5">
                <div className="w-full pl-3">
                  <div>
                    <p className="p-1 text-sm font-semibold">Gallery Image</p>
                    <input
                      id="title"
                      placeholder="Enter title"
                      value={formik.values.title}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full pl-3">
                  <div>
                    <p className="p-1 text-sm font-semibold">Image</p>
                    <input
                      id="images"
                      type="file"
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(event) =>
                        formik.setFieldValue("image", event.target.files[0])
                      }
                    />
                  </div>
                </div>
                <div className="w-full pl-3">
                  <Button type="submit" variant="contained">
                    {type === "update" ? "Update" : "Add"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
