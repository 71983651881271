import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../Config/baseUrl";


export default function CMS() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const store = localStorage.getItem("store_id");

  const getCMS = () => {
    axios
      .get(`${baseUrl}/blog/change-page-content-api/?store_id=${store}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setData(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  const deleteCMS = (id) => {
    axios
      .delete(
        `${baseUrl}/blog/change-page-content-api/`,

        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: { store_id: Number(store), id }
        }
      )
      .then((res) => {
        console.log("Blog deleted successfully");
        getCMS();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getCMS();
  }, []);
  return (
    <>
      <p className="my-3 text-5xl text-center text-blue-900 underline">CMS</p>
      <div className="flex justify-end item-center w-[100%]">
        <NavLink to="/cms/add">
          <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
            <Button variant="outlined">Add</Button>
          </div>
        </NavLink>
      </div>
      {/* <p dangerouslySetInnerHTML={{ __html: data?.content }}></p> */}
      {/* <JsxParser jsx={<JsxParser jsx={data?.content} />} /> */}
      {/* {data?.content} */}

      <div className="grid justify-between md:grid-cols-2 lg:grid-cols-3">
        {data?.map((item) => {
          return (
            <div className="flex flex-col justify-start p-4 mx-4 my-3 border border-gray-300 rounded drop-shadow-2xl">

              <p className="overflow-hidden text-xl text-black whitespace-nowrap text-ellipsis">
                {item.heading}
              </p>


              <p className="overflow-hidden text-base text-black whitespace-nowrap text-ellipsis">
                {item.sub_heading}
              </p>

              <p className="overflow-hidden text-sm text-black whitespace-nowrap text-ellipsis">
                {item.content}
              </p>



              <div className="flex items-center justify-center w-full px-5">
                <div className="px-5 mt-8 ">
                  <Button
                    onClick={() => navigate(`/cms/update`, { state: item.id })}
                    variant="outlined"
                  >
                    Update
                  </Button>
                </div>

                <div className="px-5 mt-8 ">
                  <Button onClick={() => deleteCMS(item.id)} variant="outlined">
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
