import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { baseUrl } from '../../Config/baseUrl';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const MetaPages = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const getCreer = () => {
        axios
            .get(`${baseUrl}/blog/frontend-page-id-api/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                setData(res.data?.data);
            })
            .catch((err) => {
                toast.error("Error deleting blog", err?.response?.data?.message);
            });
    };
    const deleteMeta = (id) => {
        axios
            .delete(`${baseUrl}/blog/frontend-page-id-api/`, {
                data: { page_id: id },
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                console.log("Blog deleted successfully");
                getCreer();
                toast.success(res?.data?.message);
            })
            .catch((err) => {
                toast.error("Error deleting blog", err?.response?.data?.message);
            });
    };

    useEffect(() => {
        getCreer();
    }, []);

    return (
        <div>
            <p className="my-3 text-5xl text-center text-blue-900 underline">
                Meta Pages
            </p>
            <div className="flex justify-end item-center w-[100%]">
                <NavLink to="/meta/add">
                    <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full border border-collapse border-gray-300 table-auto">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            <th className="px-4 py-2 text-lg font-semibold text-left text-black">Name</th>
                            <th className="px-4 py-2 text-lg font-semibold text-left text-black">Path</th>
                            <th className="px-4 py-2 text-lg font-semibold text-left text-black">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item) => (
                            <tr key={item.id} className="border-b hover:bg-gray-100">
                                <td className="px-4 py-2 overflow-hidden text-lg text-black whitespace-nowrap text-ellipsis">
                                    <div
                                        className="text-lg text-black cursor-pointer"
                                        onClick={() => navigate(`/metadetails/${item.id}`)}
                                    >
                                        {item.name}
                                    </div>
                                </td>
                                <td className="px-4 py-2 overflow-hidden text-lg text-black whitespace-nowrap text-ellipsis">
                                    <p className="">
                                        {item.path}
                                    </p>
                                </td>
                                <td className="px-4 py-2 text-lg text-black">
                                    <div className="flex justify-start space-x-4">
                                        <Button
                                            onClick={() => navigate(`/meta/update`, { state: item.id })}
                                            variant="outlined"
                                            className="px-4 py-2"
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            onClick={() => deleteMeta(item.id)}
                                            variant="outlined"
                                            className="px-4 py-2"
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default MetaPages