import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../Config/baseUrl";

export default function Category() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const getCreer = () => {
    axios
      .get(`${baseUrl}/blog/blog-category-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setData(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  const deleteCategory = (id) => {
    axios
      .delete(`${baseUrl}/blog/blog-category-api/`, {
        data: { category_id: id },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("Category deleted successfully");
        getCreer();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error("Error deleting ", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getCreer();
  }, []);

  return (
    <>
      <p className="my-3 text-5xl text-center text-blue-900 underline">
        Category
      </p>
      <div className="flex justify-end item-center w-[100%]">
        <NavLink to="/category/add">
          <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
            <Button variant="outlined">Add</Button>
          </div>
        </NavLink>
      </div>
      <div className="grid justify-between md:grid-cols-2 lg:grid-cols-4">
        {data?.map((item) => {
          return (
            <div className="flex flex-col justify-between p-2 mx-3 my-3 border border-gray-300 rounded drop-shadow-2xl">
              <div className="flex justify-between">
                <div className="flex flex-wrap my-3 ml-2 w-[40%]">
                  <p className="text-lg text-black">{item.name}</p>
                </div>
                <div className="flex flex-wrap ml-2  justify-evenly my-2 w-[60%]">
                  <p className="text-xl text-right text-black underline">
                    <img src={item.images} alt="" className="!w-[100%] !h-20" />
                  </p>
                </div>
              </div>
              {/* <div className="flex flex-col items-center justify-center mx-3 my-3 ">
                <p dangerouslySetInnerHTML={{ __html: item.Content }}></p>
              </div> */}

              <div className="flex items-center justify-center w-full px-5">
                <div className="px-5 mt-8 ">
                  <Button
                    onClick={() =>
                      navigate(`/category/update`, { state: item.id })
                    }
                    variant="outlined"
                  >
                    Update
                  </Button>
                </div>

                <div className="px-5 mt-8 ">
                  <Button onClick={() => deleteCategory(item.id)} variant="outlined">
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
