import { Button, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../Config/baseUrl";

export default function AddBook() {
  const { state } = useLocation();
  const { type } = useParams();
  const [data, setData] = useState();
  const [image, setImage] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [subData, setSubData] = useState([]);
  const navegate = useNavigate();

  const getBook = () => {
    axios
      .get(`${baseUrl}/blog/book-api/?book_id=${state}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("RESPONSE ==== : ", res.data);
        setData(res.data?.data);
        // handleEdit(res.data?.news_details);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    state && getBook();
  }, [state]);

  console.log(image);

  const formik = useFormik({
    initialValues: {
      publish_date: data?.publish_date || "",
      image: null,
      title: data?.title || "",
      // meta_title: data?.meta_title || "",
      // meta_description: data?.meta_description || "",
      category: data?.category || "",
      sub_category: data?.sub_category || "",
      content: data?.content || "",
      status: data?.status || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqbody = new FormData();
      reqbody.append("store", localStorage.getItem("store_id"));
      reqbody.append("publish_date", values.publish_date);
      reqbody.append("title", values.title);
      // reqbody.append("meta_title", values.meta_title);
      // reqbody.append("meta_description", values.meta_description);
      reqbody.append("category", values.category);
      reqbody.append("sub_category", values.sub_category);
      reqbody.append("content", values.content);
      reqbody.append("status", values.status);
      type !== "update"
        ? reqbody.append("image", values.image)
        : formik.values.image && reqbody.append("image", values.image);
      type === "update" && reqbody.append("book_id", state);
      type === "add"
        ? axios
            .post(
              `${baseUrl}/blog/book-api/`,
              { ...values, store: localStorage.getItem("store_id") },
              {
                headers: {
                  Authorization: `Token ${localStorage.getItem("token")}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.response_code === 200) {
                toast.success(response.data.message);
                setImage();
                navegate(-1);
              } else {
                toast.error(response.data.message);
              }
            })
            .catch((error) => {
              toast.error("Error adding book:", error);
            })
        : axios
            .patch(`${baseUrl}/blog/book-api/`, reqbody, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${localStorage.getItem("token")}`,
              },
            })
            .then((response) => {
              if (response.data.response_code === 200) {
                toast.success(response.data.message);
                setImage();
                navegate(-1);
              } else {
                toast.error(response.data.message);
              }
            })
            .catch((error) => {
              console.error("Error adding book:", error);
            });
    },
  });
  const blogData = () => {
    axios
      .get(`${baseUrl}/blog/blog-category-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setCategoryData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };
  const blogSubCategory = (id) => {
    axios
      .get(`${baseUrl}/blog/blog-subcategory-api/`, {
        params: { category_id: id },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setSubData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err.response?.data.message);
      });
  };
  useEffect(() => {
    blogSubCategory(formik.values.category);
  }, [formik.values.category]);
  useEffect(() => {
    blogData();
  }, []);
  return (
    <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
      <div className="flex flex-col justify-center w-full">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-2 border-gray-300 rounded-md ">
              <div className="grid grid-cols-2  gap-5">
                <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Title</p>

                    <input
                      id="title"
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                      value={formik.values.title}
                    />
                  </div>
                </div>
                {/* <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Meta Title</p>

                    <input
                      id="meta_title"
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                      value={formik.values.meta_title}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Sub Title</p>

                    <input
                      id="meta_description"
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                      value={formik.values.meta_description}
                    />
                  </div>
                </div> */}
                <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Publish Date</p>

                    <input
                      id="publish_date"
                      type="date"
                      placeholder=""
                      className="!w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                      value={formik.values.publish_date}
                    />
                  </div>
                </div>

                {/* <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Description:</p>
                    <input
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </div>
                </div> */}

                <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Category</p>
                    <Select
                      id="category"
                      label="Select Category"
                      className="w-full  px-4  !py-0 !h-10 !m-1 border border-gray-300 rounded"
                      onClick={() => {
                        blogData();
                      }}
                      v
                      value={formik.values.category || ""}
                      onChange={(e) => {
                        // const value =
                        //   e.target?.value === "" ? null : e.target?.value;
                        formik.setFieldValue("category", e.target?.value);
                      }}
                    >
                      {/* <MenuItem value={""}>Select category</MenuItem> */}
                      {categoryData?.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Sub Category</p>
                    <Select
                      id="sub_category"
                      placeholder="Select Sub Category"
                      className="w-full  px-4  !py-0 !h-10 !m-1 border border-gray-300 rounded"
                      // value={formik.values.sub_category}
                      // disabled={!formik.values.category}
                      value={formik?.values?.sub_category || ""}
                      onChange={(e) => {
                        // const value =
                        //   e.target?.value === "" ? null : e.target?.value;
                        formik.setFieldValue("sub_category", e.target?.value);
                      }}
                    >
                      {/* <option value={null}>{"Select sub category"}</option> */}
                      <MenuItem value={""}>Select sub category</MenuItem>
                      {subData?.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Status:</p>
                    <select
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      id="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                    >
                      <option value={null}>Select Status</option>
                      <option value="Publish">Publish</option>
                      <option value="Draft">Draft</option>
                    </select>
                  </div>
                </div>

                <div className="w-full pl-3 mt-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">File:</p>
                    <input
                      id="image"
                      type="file"
                      placeholder="Input Image"
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) =>
                        formik.setFieldValue("image", e.target.files[0])
                      }
                      value={image}
                    />
                    {/* <ImagePicker
                      placeholder="Input Image"
                      handleChange={setImage}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full pl-3 mt-3 ">
              <div>
                <p className="p-1 text-sm font-semibold">Content:</p>
                <ReactQuill
                  id="content"
                  name="content"
                  className="w-full text-lg font-semibold bg-gray-100 border border-gray-700"
                  // value={content}
                  // onChange={handleEditorChange}
                  value={formik.values.content}
                  onChange={(value) => formik.setFieldValue("content", value)}
                ></ReactQuill>
              </div>
            </div>
            <div className="flex items-center justify-center w-full pl-3 my-5 mt-3 ">
              <Button
                className="!bg-green-500"
                type="submit"
                variant="contained"
              >
                {state ? "Update" : "Add"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
