import { Button } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { baseUrl } from '../../Config/baseUrl'
import { toast } from 'react-toastify'

const Project = () => {
    const { id } = useParams()
    const [data, setData] = useState();
    const navigate = useNavigate();

    const getCreer = () => {
        axios
            .get(`${baseUrl}/blog/project-api/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                setData(res?.data?.data);
                console.log(res?.data?.data);
            })

    };
    const deleteFAQ = (ids) => {

        const formData = new FormData();
        formData.append("project_id", ids);  // Append the ids to the FormData object

        // Make an axios DELETE request
        axios
            .delete(`${baseUrl}/blog/project-api/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
                data: formData  // Pass FormData as data
            })
            .then((res) => {
                console.log("Blog deleted successfully");
                getCreer();
                toast.success(res?.data?.message);
            })
            .catch((err) => {
                toast.error("Error deleting blog", err?.response?.data?.message);
            });
    };


    useEffect(() => {
        getCreer();
    }, []);
    console.log("first", data)
    return (
        <div>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Project</p>
            <NavLink to={`/project/add`}>
                <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
                    <Button variant="outlined">Add</Button>
                </div>
            </NavLink>
            <div className="grid justify-between md:grid-cols-2 lg:grid-cols-3">
                {data?.map((item) => {
                    return (
                        <div className="flex flex-col justify-start p-2 mx-3 my-3 border border-gray-300 rounded drop-shadow-2xl" >

                            <div className="flex flex-wrap my-2 ml-2 " onClick={() => navigate(`/projectdetails/${item.id}`)}>

                                <p className="overflow-hidden text-xl text-black underline whitespace-nowrap text-ellipsis">
                                    {item.title}
                                </p>
                            </div>
                            <div className="flex flex-wrap my-3 ml-2">
                                <p className="overflow-hidden text-lg text-black whitespace-nowrap text-ellipsis">
                                    {item?.image && <img src={item.image} alt="" className="!w-[100%] !h-20" />}
                                </p>
                            </div>


                            {/* <div className="flex flex-col items-center justify-center mx-3 my-3 ">
                <p dangerouslySetInnerHTML={{ __html: item.Content }}></p>
              </div> */}

                            <div className="flex items-center justify-center w-full px-5">
                                <div className="px-5 mt-8 ">
                                    <Button
                                        onClick={() => navigate(`/project/update`, { state: item.id })}
                                        variant="outlined"
                                    >
                                        Update
                                    </Button>
                                </div>

                                <div className="px-5 mt-8 ">
                                    <Button onClick={() => deleteFAQ(item.id)} variant="outlined">
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default Project