import { Button, Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageClient from "./ManageClient";

export const headers = {
  token: localStorage.getItem("token"),
  Authorization: `Token ${localStorage.getItem("token")}`,
};
const Client = () => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const clientsFn = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://m1blog.aaragroups.com/client-api/",
        { headers }
      );
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    clientsFn();
  }, []);

  const deleteClientFn = async (client_ids) => {
    try {
      const response = await axios.delete(
        "https://m1blog.aaragroups.com/client-api/",
        { data: { client_ids }, headers }
      );
      toast.success(response.data.message);
      clientsFn();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-2">
      <p className="my-3 text-5xl text-center text-blue-900 underline">
        Client
      </p>
      <div className="flex justify-end py-2 item-center">
        <ManageClient
          refetch={clientsFn}
          setSelected={setSelected}
          selected={selected}
        />
      </div>
      <div className="grid grid-cols-4 gap-2">
        {isLoading
          ? [1, 2, 3, 4, 5, 6].map((i) => {
              return (
                <div
                  key={i}
                  className="flex flex-col p-2 bg-gray-100 rounded shadow-inner"
                >
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <Skeleton className="w-32" />
                      <Skeleton className="w-28" />
                    </div>
                    <Skeleton className="w-1/2" />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </div>
                </div>
              );
            })
          : data?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="flex flex-col p-2 bg-gray-100 rounded shadow-inner"
                >
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <p className="text-xl font-semibold">{item.name}</p>
                    </div>
                    <p className="text-sm font-semibold text-black text-opacity-60">
                      {item.designation}
                    </p>
                    <img src={item.logo} alt="" className="p-3" />
                  </div>

                  <div className="flex items-center gap-2 py-2">
                    <Button
                      onClick={() => setSelected(item)}
                      variant="outlined"
                    >
                      Update
                    </Button>

                    <Button
                      onClick={() => deleteClientFn([item.id])}
                      variant="outlined"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Client;
