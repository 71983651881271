import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { baseUrl } from '../../Config/baseUrl';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const ProjectCategory = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const getCategory = () => {
        axios
            .get(`${baseUrl}/blog/projectcategory-api/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                setData(res.data?.data);
            })
            .catch((err) => {
                toast.error("Error deleting blog", err?.response?.data?.message);
            });
    };
    const deleteCategory = (id) => {
        axios
            .delete(`${baseUrl}/blog/projectcategory-api/`, {
                data: { category_id: id },
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                console.log("Category deleted successfully");
                getCategory();
                toast.success(res?.data?.message);
            })
            .catch((err) => {
                toast.error("Error deleting Category ", err?.response?.data?.message);
            });
    };

    useEffect(() => {
        getCategory();
    }, []);
    return (
        <div>
            <p className="my-3 text-5xl text-center text-blue-900 underline">
                Project Category
            </p>
            <div className="flex justify-end item-center w-[100%]">
                <NavLink to="/Project_category/add">
                    <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <div className="grid justify-between md:grid-cols-2 lg:grid-cols-4">
                {data?.map((item) => {
                    return (
                        <div className="flex flex-col justify-between p-2 mx-3 my-3 border border-gray-300 rounded drop-shadow-2xl">
                            <div className="flex justify-between">
                                <div className="flex flex-wrap my-3 ml-2 w-[40%]">
                                    <p className="text-lg text-black">{item.name}</p>
                                </div>
                                <div className="flex flex-wrap ml-2  justify-evenly my-2 w-[60%]">
                                    <p className="text-xl text-right text-black underline">
                                        <img src={item.images} alt="" className="!w-[100%] !h-20" />
                                    </p>
                                </div>
                            </div>
                            {/* <div className="flex flex-col items-center justify-center mx-3 my-3 ">
                <p dangerouslySetInnerHTML={{ __html: item.Content }}></p>
              </div> */}

                            <div className="flex items-center justify-center w-full px-5">
                                <div className="px-5 mt-8 ">
                                    <Button
                                        onClick={() =>
                                            navigate(`/project_category/update`, { state: item.id })
                                        }
                                        variant="outlined"
                                    >
                                        Update
                                    </Button>
                                </div>

                                <div className="px-5 mt-8 ">
                                    <Button onClick={() => deleteCategory(item.id)} variant="outlined">
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default ProjectCategory