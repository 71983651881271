import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../Config/baseUrl";

export default function Book() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const getBook = () => {
    axios
      .get(`${baseUrl}/blog/book-api/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response.data.response_code === 200) {
          toast.success(response.data.message);
          setData(response.data?.data);
          // blogData();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  const deleteFAQ = (id) => {
    axios
      .delete(
        `${baseUrl}/blog/book-api/`,

        {
          data: { book_id: id },
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        getBook();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getBook();
  }, []);

  return (
    <>
      <p className="my-3 text-5xl text-center text-blue-900 underline">Book</p>
      <div className="flex justify-end item-center w-[100%]">
        <NavLink to="/book/add">
          <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
            <Button variant="outlined">Add</Button>
          </div>
        </NavLink>
      </div>
      <div className="grid justify-between md:grid-cols-2 lg:grid-cols-3">
        {data?.map((item) => {
          return (
            <div className="flex flex-col justify-start p-2 mx-3 my-3 border border-gray-300 rounded drop-shadow-2xl">
              <div className="flex justify-between">
                <div className="flex flex-wrap my-3 ml-2 w-[40%]">
                  <p className="text-lg text-black">{item.title}</p>
                </div>
                <div className="flex flex-wrap ml-2  justify-evenly my-2 w-[60%]">
                  <p className="text-xl text-black text-right  underline">
                    <img src={item.image} alt="" className="!w-[100%] !h-20" />
                  </p>
                </div>
              </div>

              {/* <div className="flex flex-col items-center justify-center mx-3 my-3 ">
                <p dangerouslySetInnerHTML={{ __html: item.Content }}></p>
              </div> */}

              <div className="flex items-center justify-center w-full px-5">
                <div className="px-5 mt-8 ">
                  <Button
                    onClick={() => navigate(`/book/update`, { state: item.id })}
                    variant="outlined"
                  >
                    Update
                  </Button>
                </div>

                <div className="px-5 mt-8 ">
                  <Button onClick={() => deleteFAQ(item.id)} variant="outlined">
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
