import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ImagePicker } from "../../../Shared/useImageCrop/ImagePicker";

export default function UpdateCareer() {
  const [title, setTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [content, setContent] = useState("");
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);
  const { id } = useParams();
  const navegate = useNavigate();

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleEdit = (prev) => {
    if (prev) {
      setContent(prev?.[0]?.content);
      setTitle(prev?.[0]?.Title);
      setMetaKeyword(prev?.[0]?.meta_keyword);
      setMetaDescription(prev?.[0]?.meta_description);
    }
  };

  const addnews = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("Title", title);
    reqbody.append("meta_keyword", metaKeyword);
    reqbody.append("meta_description", metaDescription);
    reqbody.append("Content", content);
    image && reqbody.append("images", image);

    axios
      .put(
        `https://m1blog.aaragroups.com/blog/api/admin/api/job-deatils-api/?id=${id}`,
        reqbody,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        navegate("/career");
      })
      .catch((error) => {
        console.error("Error adding blog:", error);
      });
  };
  const getNews = () => {
    axios
      .post(
        "https://m1blog.aaragroups.com/blog/store-based-jobs-list-api/",
        {},
        {
          headers: {
            token: localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log("RESPONSE ==== : ", res.data?.blog_list);
        setData(res.data?.news_details);
        handleEdit(res.data?.news_details);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        alert(err.response.data.message);
      });
  };

  useEffect(() => {
    getNews();
  }, []);

  const dataNews = data?.filter((i) => i.id === Number(id));

  useEffect(() => {
    if (dataNews) {
      setContent(dataNews?.[0]?.Content);
    }
  }, [data]);

  //   const dataNews=(data?.filter((i)=>i.id===id))

  return (
    <div className="flex flex-col justify-center gap-5 lg:flex-row">
      <div className="flex flex-col justify-center w-full lg:w-3/5">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={addnews}>
            <div className="p-2 border-gray-300 rounded-md ">
              <div className="flex-col gap-5">
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Title</p>
                    <input
                      value={title}
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">
                      Meta Description:
                    </p>
                    <input
                      value={metaDescription}
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Meta Keyword:</p>
                    <input
                      value={metaKeyword}
                      placeholder=""
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(e) => setMetaKeyword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">File:</p>
                    <ImagePicker
                      placeholder="Input Image"
                      handleChange={setImage}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <p className="p-1 text-sm font-semibold">Content:</p>
                  <ReactQuill
                    style={{ height: "500px" }}
                    className="w-full text-lg font-semibold bg-gray-100 border "
                    value={content}
                    onChange={handleEditorChange}
                  ></ReactQuill>
                </div>

                <div className="w-full pl-3 mt-5 ">
                  <Button type="submit" variant="contained">
                    Update News
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
