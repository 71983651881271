import { Outlet } from "react-router-dom";
import Header from "../Shared/Header";
import Sidebar from "../Shared/Sidebar";

const Layout = ({ component }) => {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-col w-full gap-2 p-2 bg-gray-100">
        <Header />
        <div className="flex flex-col overflow-y-auto bg-white rounded-lg">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
