import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Career() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const getCreer = () => {
    axios
      .post(
        "https://m1blog.aaragroups.com/blog/store-based-jobs-list-api/",
        {},
        {
          headers: {
            token: localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res.data?.news_details);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  const deleteCreear = (id) => {
    axios
      .delete(
        `https://m1blog.aaragroups.com/blog/api/admin/api/job-deatils-api/?id=${id}`,

        {
          headers: {
            token: localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        console.log("Blog deleted successfully");
        getCreer();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getCreer();
  }, []);

  return (
    <>
      <p className="my-3 text-5xl text-center text-blue-900 underline">
        Career
      </p>
      <div className="flex justify-between item-center w-[100%]">
        <NavLink to="/addcareer">
          <div className="text-xl font-bold !text-gray-600 p-3">
            <Button variant="outlined">Add</Button>
          </div>
        </NavLink>
      </div>
      <div className="grid justify-between grid-cols-3">
        {data?.map((item) => {
          return (
            <div className="justify-between p-2 mx-3 my-3 border border-gray-300 rounded drop-shadow-2xl">
              <div className="flex flex-col justify-center mx-2 my-2 ">
                <p className="text-xl text-black ">{item.Experience} </p>
              </div>
              <div className="flex flex-col items-center justify-center my-3 ">
                <p className="text-2xl text-black underline">{item.Title}</p>
              </div>
              <div className="flex flex-col items-center justify-center my-5">
                <img
                  className="w-48 h-48"
                  src={`${item.images}?${Date.now()}`}
                  alt="img"
                />
              </div>
              <div className="flex flex-col items-center justify-center mx-3 my-3 ">
                <p dangerouslySetInnerHTML={{ __html: item.Content }}></p>
              </div>

              <div className="flex items-center justify-center w-full px-5">
                <div className="px-5 mt-8 ">
                  <Button
                    onClick={() => navigate(`/updateCareer/${item.id}`)}
                    variant="outlined"
                  >
                    Update
                  </Button>
                </div>

                <div className="px-5 mt-8 ">
                  <Button
                    onClick={() => deleteCreear(item.id)}
                    variant="outlined"
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
