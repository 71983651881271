import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { baseUrl } from '../../../Config/baseUrl';
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

const AddMetaDetails = () => {
    const { id } = useParams()
    const { state } = useLocation()
    const { type } = useParams();
    const [data, setData] = useState();
    const navegate = useNavigate();
    const getAddMeta = () => {
        axios
            .get(`${baseUrl}/blog/meta-api/?metamodel_id=${state}`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                console.log("RESPONSE ==== : ", res.data);
                setData(res.data?.data);
                // handleEdit(res.data?.news_details);
            })
            .catch((err) => {
                console.log("ERROR: ====", err);
                toast.error(err.message);
            });
    };



    useEffect(() => {
        state && getAddMeta();
    }, [state])
    console.log(data);

    const formik = useFormik({
        initialValues: {
            page_title: id,
            meta_title: data?.meta_title || "",
            meta_tag: data?.meta_tag || "",
            meta_keyword: data?.meta_keyword || "",
            meta_description: data?.meta_descriptiong || "",
            canonical: data?.canonical || "",
            og_title: data?.og_title || "",
            og_image: data?.og_image || "",
            og_discription: data?.og_discription || "",
            og_type: data?.og_type || "",
            og_sitename: data?.og_sitename || "",
            og_url: data?.og_url || "",
            twitter_card: data?.twitter_card || "",
            twitter_description: data?.twitter_description || "",
            twitter_image: data?.twitter_image || "",
            alternate: data?.alternate || "",
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const reqbody = new FormData();
            reqbody.append("store", localStorage.getItem("store_id"));
            reqbody.append("meta_title", values.meta_title);
            reqbody.append("meta_tag", values.meta_tag);
            reqbody.append("meta_keyword", values.meta_keyword);
            reqbody.append("meta_description", values.meta_description);
            reqbody.append("canonical", values.canonical);
            reqbody.append("og_title", values.og_title);
            reqbody.append("og_image", values.og_image);
            reqbody.append("og_discription", values.og_discription);
            reqbody.append("og_type", values.og_type);
            reqbody.append("og_sitename", values.og_sitename);
            reqbody.append("og_url", values.og_url);
            reqbody.append("twitter_card", values.twitter_card);
            reqbody.append("twitter_description", values.twitter_description);
            reqbody.append("twitter_image", values.twitter_image);
            reqbody.append("alternate", values.alternate);



            reqbody.append("page_id", id)
            type === "update" && reqbody.append("metamodel_id", state)
            type === "add"
                ? axios
                    .post(
                        `${baseUrl}/blog/meta-api/`,
                        { ...values, store: localStorage.getItem("store_id") },
                        {
                            headers: {
                                Authorization: `Token ${localStorage.getItem("token")}`,
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((response) => {
                        toast.success(response.data.message);
                        navegate(`/metadetails/${id}`);
                    })
                    .catch((error) => {
                        toast.error("Error adding blog:", error);
                    })
                : axios
                    .patch(`${baseUrl}/blog/meta-api/`, reqbody, {
                        data: {
                            metamodel_id: id
                        },
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Token ${localStorage.getItem("token")}`,
                        },
                    })
                    .then((response) => {
                        toast.success(response.data.message);
                        navegate(`/metadetails/${id}`);
                    })
                    .catch((error) => {
                        console.error("Error adding blog:", error);
                    });
        },
    });
    /* const { id } = useParams()
    const { state } = useLocation();
    const { type } = useParams();
    const [data, setData] = useState();
    const navegate = useNavigate();
    const getFAQ = () => {
      axios
        .get(`${baseUrl}/blog/meta-api/?faq_id=${state}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          console.log("RESPONSE ==== : ", res.data);
          setData(res.data?.data);
          // handleEdit(res.data?.news_details);
        })
        .catch((err) => {
          console.log("ERROR: ====", err);
          toast.error(err.message);
        });
    };
  
    useEffect(() => {
      state && getFAQ();
    }, [state]);
  
    console.log(data);
  
    const formik = useFormik({
      initialValues: {
        page_id: id,
        question: data?.question || "",
        answer: data?.answer || "",
      },
      enableReinitialize: true,
      onSubmit: (values) => {
        const reqbody = new FormData();
        reqbody.append("store", localStorage.getItem("store_id"));
        reqbody.append("question", values.question);
        reqbody.append("answer", values.answer);
        reqbody.append("page_id", id)
        type === "update" && reqbody.append("faq_id", state);
        type === "add"
          ? axios
            .post(
              `${baseUrl}/blog/faq-api/`,
              { ...values, store: localStorage.getItem("store_id") },
              {
                headers: {
                  Authorization: `Token ${localStorage.getItem("token")}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              toast.success(response.data.message);
              navegate(`/faq/${id}`);
            })
            .catch((error) => {
              toast.error("Error adding blog:", error);
            })
          : axios
            .patch(`${baseUrl}/blog/faq-api/`, reqbody, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${localStorage.getItem("token")}`,
              },
            })
            .then((response) => {
              toast.success(response.data.message);
              navegate(`/faq/${id}`);
            })
            .catch((error) => {
              console.error("Error adding blog:", error);
            });
      },
    }); */

    return (
        <div>
            <div className="flex flex-col justify-center w-full lg:w-3/5">
                <div className="mt-5 bg-white shadow-lg">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-2 border-gray-300 rounded-md ">
                            <div className="flex flex-col gap-5">

                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Meta_title</p>
                                        <input
                                            id="meta_title"
                                            placeholder="Enter title"
                                            value={formik.values.meta_title}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Meta_tag"</p>
                                        <input
                                            id="meta_tag"
                                            placeholder="Enter title"
                                            value={formik.values.meta_tag}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}

                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">meta_keyword</p>
                                        <input
                                            id="meta_keyword"
                                            value={formik.values.meta_keyword}
                                            placeholder=''
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Meta_Description</p>
                                        <input
                                            id="meta_description"
                                            value={formik.values.meta_description}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">canonical</p>
                                        <input
                                            id="canonical"
                                            value={formik.values.canonical}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Og_Title</p>
                                        <input
                                            id="og_title"
                                            value={formik.values.og_title}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Og_Image</p>
                                        <input
                                            id="og_image"
                                            type='file'
                                            placeholder="Enter title"

                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={(event) =>
                                                formik.setFieldValue("og_image", event.target.files[0])
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Og_Discription</p>
                                        <input
                                            id="og_discription"
                                            placeholder="Enter title"
                                            value={formik.values.og_discription}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}

                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Og Type</p>
                                        <input
                                            id="og_type"
                                            value={formik.values.og_type}
                                            placeholder=''
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Og Sitename</p>
                                        <input
                                            id="og_sitename"
                                            value={formik.values.og_sitename}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold"> og_url</p>
                                        <input
                                            id="og_url"
                                            value={formik.values.og_url}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">twitter_card</p>
                                        <input
                                            id="twitter_card"
                                            value={formik.values.twitter_card}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">twitter_description</p>
                                        <input
                                            id="twitter_description"
                                            value={formik.values.twitter_description}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">twitter_image</p>
                                        <input
                                            id="twitter_image"
                                            type='file'

                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={(event) =>
                                                formik.setFieldValue("twitter_image", event.target.files[0])
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Alternate</p>
                                        <input
                                            id="alternate"
                                            value={formik.values.alternate}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <Button type="submit" variant="contained">
                                        Add
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddMetaDetails