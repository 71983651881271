import { Button, Rating, Skeleton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageTestimonials from "./ManageTestimonials";

export const headers = {
  token: localStorage.getItem("token"),
  Authorization: `Token ${localStorage.getItem("token")}`,
};
const Testimonials = () => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const testimonialsFn = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        "https://m1blog.aaragroups.com/client-feedback-api/",
        { headers }
      );
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    testimonialsFn();
  }, []);

  const deleteTestimonialsFn = async (client_feedback_ids) => {
    try {
      const response = await axios.delete(
        "https://m1blog.aaragroups.com/client-feedback-api/",
        { data: { client_feedback_ids }, headers }
      );
      toast.success(response?.data?.message);
      testimonialsFn();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-2">
      <p className="my-3 text-5xl text-center text-blue-900 underline">
        Testimonials
      </p>
      <div className="flex justify-end py-2 item-center">
        <ManageTestimonials
          refetch={testimonialsFn}
          setSelected={setSelected}
          selected={selected}
        />
      </div>
      <div className="grid grid-cols-2 gap-2">
        {isLoading
          ? [1, 2, 3, 4, 5, 6].map((i) => {
              return (
                <div
                  key={i}
                  className="flex flex-col p-2 bg-gray-100 rounded shadow-inner"
                >
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <Skeleton className="w-52" />
                      <Skeleton className="w-48" />
                    </div>
                    <Skeleton className="w-1/2" />
                    <Skeleton />
                    <Skeleton />
                  </div>
                </div>
              );
            })
          : data?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="flex flex-col p-2 bg-gray-100 rounded shadow-inner"
                >
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <p className="text-xl font-semibold">{item.client}</p>
                      <Rating readOnly value={item.feedback} />
                    </div>
                    <p className="text-black font-semibol text-opacity-80">
                      {item.designation}
                    </p>
                    <p>{item.comment}</p>
                    {item.url && (
                      <iframe
                        width="400"
                        height="240"
                        src={item.url}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                        className="rounded"
                      ></iframe>
                    )}
                  </div>

                  <div className="flex items-center gap-2 py-2">
                    <Button
                      onClick={() => setSelected(item)}
                      variant="outlined"
                    >
                      Update
                    </Button>

                    <Button
                      onClick={() => deleteTestimonialsFn([item.id])}
                      variant="outlined"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default Testimonials;
