import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import Capture from "../../Assets/logo1.png";
import { navItems } from "../../Mock";

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <List className="!relative !p-2 min-w-fit">
      <ListItem className="!py-0">
        <img alt="" className="Capture !h-36 !w-60" src={Capture} />
      </ListItem>
      <Divider />
      {navItems?.map((nav, idx) => {
        return (
          <ListItemButton
            key={idx}
            onClick={() => navigate(nav.navLink)}
            className={classNames(
              "!rounded !p-2",
              window.location.pathname === nav.navLink && "!bg-gray-200"
            )}
          >
            <ListItemIcon
              className={classNames(
                window.location.pathname === nav.navLink && "!text-white"
              )}
            >
              {nav.navIcon}
            </ListItemIcon>
            {nav.navItem}
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default Sidebar;
