import { Button } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../Config/baseUrl";
import ReactQuill from "react-quill";

export default function AddProject() {
  const { id } = useParams()
  const { state } = useLocation();
  const { type } = useParams();
  const [data, setData] = useState();
  const navegate = useNavigate();
  const getProject = () => {
    axios
      .get(`${baseUrl}/blog/project-api/?project_id=${state}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("RESPONSE ==== : ", res.data);
        setData(res.data?.data);

      })
      .catch((err) => {
        console.log("ERROR: ====", err);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    state && getProject();
  }, [state]);

  console.log(data);

  const formik = useFormik({
    initialValues: {
      page_id: id,
      title: data?.title || "",
      image: data?.image || "",
      content: data?.content || "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqbody = new FormData();
      reqbody.append("store", localStorage.getItem("store_id"));
      reqbody.append("content", values.content);
      reqbody.append("title", values.title);
      reqbody.append("image", values.image);
      reqbody.append("page_id", id)
      type === "update" && reqbody.append("project_id", state);
      type === "add"
        ? axios
          .post(
            `${baseUrl}/blog/project-api/`,
            { ...values, store: localStorage.getItem("store_id") },
            {
              headers: {
                Authorization: `Token ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            toast.success(response.data.message);
            navegate(`/project`);
          })
          .catch((error) => {
            toast.error("Error adding blog:", error);
          })
        : axios
          .patch(`${baseUrl}/blog/project-api/`, reqbody, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            toast.success(response.data.message);
            navegate(`/project`);
          })
          .catch((error) => {
            console.error("Error adding blog:", error);
          });
    },
  });



  return (
    <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
      <div className="flex flex-col justify-center w-full lg:w-3/5">
        <div className="mt-5 bg-white shadow-lg">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-2 border-gray-300 rounded-md ">
              <div className="flex flex-col gap-5">

                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">title</p>
                    <input
                      id="title"
                      placeholder="Enter title"
                      value={formik.values.title}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Content</p>
                    <input
                      id="content"
                      placeholder="Enter title"
                      value={formik.values.content}
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={formik.handleChange}

                    />
                  </div>
                </div>
                <div className="w-full pl-3 ">
                  <div>
                    <p className="p-1 text-sm font-semibold">Image</p>
                    <input
                      id="images"
                      type="file"
                      className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                      onChange={(event) =>
                        formik.setFieldValue("image", event.target.files[0])
                      }
                    />
                  </div>
                </div>

                <div className="w-full pl-3 ">
                  <Button type="submit" variant="contained">
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

