import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { baseUrl } from '../../../Config/baseUrl';
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

const AddMetaPages = () => {
    const { state } = useLocation();
    const { type } = useParams();
    const [data, setData] = useState();
    const navegate = useNavigate();
    // const handleEditorChange = (value) => {
    //   setContent(value);
    // };

    const getCategory = () => {
        axios
            .get(`${baseUrl}/blog/frontend-page-id-api/?page_id=${state}`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                console.log("RESPONSE ==== : ", res.data);
                setData(res.data?.data);
                // handleEdit(res.data?.news_details);
            })
            .catch((err) => {
                console.log("ERROR: ====", err);
                toast.error(err.message);
            });
    };

    useEffect(() => {
        state && getCategory();
    }, [state]);

    console.log(data);

    const formik = useFormik({
        initialValues: {
            name: data?.name || "",
            path: data?.path || "",
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            const reqbody = new FormData();
            reqbody.append("store", localStorage.getItem("store_id"));
            reqbody.append("name", values.name);
            // reqbody.append("path", values.path);
            type !== "update"
                ? reqbody.append("path", values.path)
                : formik.values.path && reqbody.append("path", values.path);
            type === "update" && reqbody.append("page_id", state);
            type === "add"
                ? axios
                    .post(
                        `${baseUrl}/blog/frontend-page-id-api/`,
                        { ...values, store: localStorage.getItem("store_id") },
                        {
                            headers: {
                                Authorization: `Token ${localStorage.getItem("token")}`,
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    )
                    .then((response) => {
                        if (response.data.response_code === 200) {
                            toast.success(response.data.message);
                            navegate(-1);
                        } else {
                            toast.error(response.data.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("Error adding blog:", error);
                    })
                : axios
                    .patch(`${baseUrl}/blog/frontend-page-id-api/`, reqbody, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Token ${localStorage.getItem("token")}`,
                        },
                    })
                    .then((response) => {
                        if (response.data.response_code === 200) {
                            toast.success(response.data.message);
                            navegate(-1);
                        } else {
                            toast.error(response.data.message);
                        }
                    })
                    .catch((error) => {
                        console.error("Error adding Category:", error);
                    });
        },
    });
    return (
        <div className="flex flex-col justify-center gap-5 py-4 lg:flex-row">
            <div className="flex flex-col justify-center w-full lg:w-3/5">
                <div className="mt-5 bg-white shadow-lg">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-2 border-gray-300 rounded-md ">
                            <div className="flex flex-col gap-5">
                                <div className="w-full pl-3 ">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">FAQ Page</p>
                                        <input
                                            id="name"
                                            placeholder="Enter page name"
                                            value={formik.values.name}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3">
                                    <div>
                                        <p className="p-1 text-sm font-semibold">Page Path</p>
                                        <input
                                            id="path"
                                            placeholder="Enter path"
                                            value={formik.values.path}
                                            className="w-full p-2 px-4 m-1 border border-gray-300 rounded"
                                            onChange={(e) => {

                                                formik.setFieldValue("path", e.target.value.replace(/ /g, "_"));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="w-full pl-3 ">
                                    <Button type="submit" variant="contained">
                                        {state ? "Update" : "Add"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddMetaPages