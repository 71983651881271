import { Button } from '@mui/material';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { deleteSliderByIdFn, getSliderFn } from '../../Services/Slider';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { imageFormat } from '../../Config/baseUrl';
import DOMPurify from 'dompurify';

const Slider = () => {
    const navigate = useNavigate();

    const { data: getSliderResponse, refetch } = useQuery({
        queryKey: "get-slider",
        queryFn: getSliderFn
    });

    const { mutate: deleteSlider } = useMutation(deleteSliderByIdFn, {
        onSuccess: (response) => {
            if (response?.data?.response_code === 200) {
                toast.success("Deleted Successfully");
                refetch();
            }
        }
    });

    const deleteHandle = (id) => {
        const formData = new FormData();
        formData.append("slider_id", id);
        deleteSlider(formData);
    };

    return (
        <div>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Slider</p>
            <div className="flex justify-between item-center w-[100%]">
                <NavLink to="/slider/add">
                    <div className="text-xl font-bold !text-gray-600 p-3">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <div className="grid justify-between md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
                {getSliderResponse?.data?.data?.map((item) => {
                    const sanitizedTitle = DOMPurify.sanitize(item.title); // Sanitize the title

                    return (
                        <div key={item.id} className="flex flex-col justify-between p-2 mx-3 my-3 border border-gray-300 rounded drop-shadow-2xl">
                            <div className="flex justify-between">
                                <div className="flex flex-wrap my-3 ml-2 w-[40%]">
                                    <p className="text-green-600 underline" dangerouslySetInnerHTML={{ __html: sanitizedTitle }} /> {/* Render with dangerouslySetInnerHTML */}
                                </div>
                                <div className="flex flex-wrap ml-2 justify-evenly my-2 w-[60%]">
                                    <p className="text-xl text-right text-black underline">
                                        {item?.image && <img src={item.image} alt="" className="!w-[100%] !h-20" />}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center justify-center w-full px-5">
                                <div className="px-5 mt-8">
                                    <Button
                                        onClick={() =>
                                            navigate(`/slider/update`, { state: item.id })
                                        }
                                        variant="outlined"
                                    >
                                        Update
                                    </Button>
                                </div>

                                <div className="px-5 mt-8">
                                    <Button
                                        onClick={() => deleteHandle(item.id)}
                                        variant="outlined"
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Slider;
