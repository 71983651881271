import { Add, Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { headers } from "..";

const ManagePartners = ({ selected, setSelected, refetch }) => {
  const [open, setOpen] = useState(false);

  const addPartnersFn = async (reqbody) => {
    try {
      const response = await axios.post(
        "https://m1blog.aaragroups.com/partner-api/",
        reqbody,
        { headers }
      );
      toast.success(response.data.message);
      refetch();
      formik.resetForm();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updatePartnersFn = async (reqbody) => {
    try {
      const response = await axios.put(
        "https://m1blog.aaragroups.com/partner-api/",
        reqbody,
        { headers }
      );
      toast.success(response.data.message);
      refetch();
      setSelected(null);
      formik.resetForm();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      logo: "",
      name: selected?.name ?? "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const reqbody = new FormData();
      values.logo && reqbody.append("logo", values.logo);
      reqbody.append("name", values.name);

      selected && reqbody.append("partner_id", selected.id);
      selected ? updatePartnersFn(reqbody) : addPartnersFn(reqbody);
    },
  });

  return (
    <>
      <Button
        startIcon={<Add />}
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        Partner
      </Button>
      <Dialog
        open={Boolean(open || selected)}
        onClose={() => {
          setOpen(false);
          setSelected(null);
        }}
        PaperProps={{ className: "w-[600px]" }}
      >
        <DialogTitle className="flex items-center !p-2 justify-between">
          <p>Partner</p>
          <IconButton
            size="small"
            onClick={() => {
              setOpen(false);
              setSelected(null);
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              margin="dense"
              type="file"
              fullWidth
              inputProps={{ className: "!pt-1.5 !pl-1.5 !pb-3" }}
              size="small"
              name="logo"
              onChange={(event) =>
                formik.setFieldValue("logo", event.target.files[0])
              }
            />
            <TextField
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              size="small"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />

            <DialogActions className="!px-0">
              <Button
                disableElevation
                onClick={() => setOpen(false)}
                variant="contained"
                color="inherit"
              >
                Cancel
              </Button>
              <Button
                disableElevation
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default ManagePartners;
