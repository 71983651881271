import { Button } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../../Config/baseUrl";

export default function ContactUs() {
  const [data, setData] = useState([]);

  const getCreer = () => {
    axios
      .get(`${baseUrl}/blog/add-contact-us/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        setData(res.data?.data);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };
  const deleteFAQ = (id) => {
    axios
      .delete(`${baseUrl}/blog/add-contact-us/`, {
        data: { contactus_id: id },
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        console.log("Blog deleted successfully");
        getCreer();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error("Error deleting blog", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getCreer();
  }, []);
  console.log(data);
  return (
    <>
      <p className="my-3 text-5xl text-center text-blue-900 underline">
        Contact Us
      </p>
      <div className="flex justify-end item-center w-[100%]">
        {/* <NavLink to="/category/add">
          <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
            <Button variant="outlined">Add</Button>
          </div>
        </NavLink> */}
      </div>
      <div className="grid justify-between md:grid-cols-2 lg:grid-cols-4">
        {data?.map((item) => {
          return (
            <div className="flex flex-col justify-between p-2 mx-3 my-3 border border-gray-300 rounded drop-shadow-2xl" key={item.id}>
              <div className="flex flex-col">
                <div className="flex flex-col flex-wrap my-3 ml-2 ">
                  <div className="flex gap-4">
                    <p className="flex justify-between pb-2 text-xs font-bold text-black capitalize">
                      Name
                    </p>
                    <p className="flex flex-wrap justify-between pb-2 text-xs text-black capitalize">
                      {item.first_name + " " + item.last_name}
                    </p>
                  </div>
                  <div className="flex gap-3">
                    <p className="flex justify-between pb-1 text-xs font-bold text-black ">
                      Email
                    </p>
                    <p className="text-xs text-black capitalize break-words">
                      {item.email}
                    </p>
                  </div>
                  <div className="flex gap-4">
                    <p className="flex justify-between pb-1 text-xs font-bold text-black capitalize">
                      Mobile
                    </p>{" "}
                    <p className="flex flex-wrap text-xs text-black capitalize">
                      {item.mobile ? item.mobile : "- -"}
                    </p>
                  </div>
                  <div className="flex gap-4">
                    <p className="flex justify-between pb-1 text-xs font-bold text-black capitalize">
                      Date
                    </p>{" "}
                    <p className="flex flex-wrap text-xs text-black capitalize">
                      {moment(item.created_date).format("(hh:mm) DD-MM-YYYY ")}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-col items-center justify-center mx-3 my-3 ">
                <p dangerouslySetInnerHTML={{ __html: item.Content }}></p>
              </div> */}

              <div className="flex items-center justify-center w-full px-5">
                {/* <div className="px-5 mt-8 ">
                  <Button
                    onClick={() =>
                      navigate(`/category/update`, { state: item.id })
                    }
                    variant="outlined"
                  >
                    Update
                  </Button>
                </div> */}

                <div className="px-5 mt-8 ">
                  <Button onClick={() => deleteFAQ(item.id)} variant="outlined">
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
