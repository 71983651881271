import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../Config/baseUrl";


export default function ProjectDetails() {
    const { id } = useParams()
    const [data, setData] = useState();
    const navigate = useNavigate();

    const getProject = () => {
        axios
            .get(`${baseUrl}/blog/project-image-api/?project_id=${id}`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                setData(res?.data?.data);
                console.log(res?.data?.data);
            })

    };
    const deleteFAQ = (ids) => {
        axios
            .delete(
                `${baseUrl}/blog/project-image-api/`,

                {
                    data: {
                        project_image_id: ids
                    },
                    headers: {
                        Authorization: `Token ${localStorage.getItem("token")}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
            .then((res) => {
                console.log("Blog deleted successfully");
                getProject();
                toast.success(res?.data?.message);
            })
            .catch((err) => {
                toast.error("Error deleting blog", err?.response?.data?.message);
            });
    };

    useEffect(() => {
        getProject();
    }, []);
    console.log("first", data)
    return (
        <>
            <p className="my-3 text-5xl text-center text-blue-900 underline">Project</p>
            <div className="flex justify-end item-center w-[100%]">
                <NavLink to={`/projectdetails/${id}/add`}>
                    <div className="text-xl font-bold !text-gray-600 p-3 pr-[10%]">
                        <Button variant="outlined">Add</Button>
                    </div>
                </NavLink>
            </div>
            <div className="grid justify-between md:grid-cols-2 lg:grid-cols-3">
                {data?.map((item) => {
                    return (
                        <div className="flex flex-col justify-start p-2 mx-3 my-3 border border-gray-300 rounded drop-shadow-2xl">
                            <div className="flex flex-wrap my-2 ml-2 ">
                                <p className="overflow-hidden text-xl text-black underline whitespace-nowrap text-ellipsis">
                                    {item.title}
                                </p>
                            </div>
                            <div className="flex flex-wrap my-3 ml-2">
                                <p className="overflow-hidden text-lg text-black whitespace-nowrap text-ellipsis">
                                    <img src={item.image} alt="" />
                                </p>
                            </div>

                            {/* <div className="flex flex-col items-center justify-center mx-3 my-3 ">
                <p dangerouslySetInnerHTML={{ __html: item.Content }}></p>
              </div> */}

                            <div className="flex items-center justify-center w-full px-5">
                                <div className="px-5 mt-8 ">
                                    <Button
                                        onClick={() => navigate(`/projectdetails/${id}/update`, { state: item.id })}
                                        variant="outlined"
                                    >
                                        Update
                                    </Button>
                                </div>

                                <div className="px-5 mt-8 ">
                                    <Button onClick={() => deleteFAQ(item.id)} variant="outlined">
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
