import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function News() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const getNews = () => {
    axios
      .post(
        "https://m1blog.aaragroups.com/blog/store-based-news-list-api/",
        {},
        {
          headers: {
            token: localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res.data?.news_details);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const deleteNews = (id) => {
    axios
      .delete(
        `https://m1blog.aaragroups.com/blog/api/admin/api/news_deatils_api/?id=${id}`,

        {
          headers: {
            token: localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        getNews();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <>
      <p className="my-3 text-5xl text-center text-blue-900 underline">News</p>
      <div className="flex justify-between ">
        <div>
          <div className="flex justify-between w-full item-center">
            <NavLink to="/addnews">
              <div className="text-xl font-bold !text-gray-600 p-3">
                <Button variant="outlined">Add News</Button>
              </div>
            </NavLink>
          </div>
          <div className="flex flex-row flex-wrap">
            {data?.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="flex justify-between p-4 m-3 border border-gray-300 rounded drop-shadow-2xl w-fit"
                >
                  <div className="flex flex-col items-center justify-center">
                    <p className="px-4 py-4 text-2xl text-black">
                      {item.Title}
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: item.Content }}></p>
                  </div>
                  <div className="flex-row justify-center mt-9">
                    <div className="flex flex-col items-center justify-center w-full">
                      <img
                        src={`${item.images}?${Date.now()}`}
                        key={item.images}
                        alt="ab"
                        className="h-44 w-44"
                      />
                    </div>
                    <div className="flex items-center justify-center w-full px-5">
                      <div className="px-5 mt-8 ">
                        <Button
                          onClick={() => navigate(`/newsupdate/${item.id}`)}
                          variant="outlined"
                        >
                          Update
                        </Button>
                      </div>

                      <div className="px-5 mt-8 ">
                        <Button
                          onClick={() => deleteNews(item.id)}
                          variant="outlined"
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
