import { privateAxiosIntance } from "../../Config/axios";
import { protectedRoutes } from "../../Config/urls";


export const getGalleryImageFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.gallery_image);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getGalleryImageByIdFn = (id) => {
    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.gallery_image}?gallery_image_id=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteGalleryImageByIdFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.gallery_image}`, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postGalleryImageFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.gallery_image, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchGalleryImageFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.gallery_image, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}