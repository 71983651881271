import { privateAxiosIntance } from "../../Config/axios";
import { protectedRoutes } from "../../Config/urls";


export const getSliderFn = () => {
    try {
        const response = privateAxiosIntance.get(protectedRoutes.slider);
        return response;
    } catch (error) {
        throw error;
    }
}

export const getSliderByIdFn = (id) => {

    try {
        const response = privateAxiosIntance.get(`${protectedRoutes.slider}`,
            {
                params: id
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteSliderByIdFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.delete(`${protectedRoutes.slider}`, { data: reqBody });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postSliderFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.post(protectedRoutes.slider, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}

export const patchSliderFn = (reqBody) => {
    try {
        const response = privateAxiosIntance.patch(protectedRoutes.slider, reqBody);
        return response;
    } catch (error) {
        throw error;
    }
}